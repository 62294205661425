.opacity-25 {
  opacity: .25 !important; }

.opacity-50 {
  opacity: .5 !important; }

.opacity-75 {
  opacity: .75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.text-tiny {
  font-size: 70% !important; }

.text-big {
  font-size: 112% !important; }

.text-large {
  font-size: 150% !important; }

.text-xlarge {
  font-size: 170% !important; }

.line-height-1 {
  line-height: 1 !important; }

.line-height-condenced {
  line-height: 1.3 !important; }

.line-height-inherit {
  line-height: inherit !important; }

.text-expanded {
  letter-spacing: 0.06991051em !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-semibold {
  font-weight: 500 !important; }

.font-weight-bolder {
  font-weight: 900 !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-transform-none {
  text-transform: none !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-scroll {
  overflow: scroll !important; }

.overflow-auto {
  overflow: auto !important; }

.box-shadow-none {
  box-shadow: none !important; }

.cursor-pointer {
  cursor: pointer !important; }

.container-p-x {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }
  @media (min-width: 992px) {
    .container-p-x {
      padding-right: 2rem !important;
      padding-left: 2rem !important; } }

.container-m--x {
  margin-right: -1rem !important;
  margin-left: -1rem !important; }
  @media (min-width: 992px) {
    .container-m--x {
      margin-right: -2rem !important;
      margin-left: -2rem !important; } }

.container-p-y:not([class^="pt-"]):not([class*=" pt-"]) {
  padding-top: 1.5rem !important; }

.container-p-y:not([class^="pb-"]):not([class*=" pb-"]) {
  padding-bottom: 1.5rem !important; }

.container-m--y:not([class^="mt-"]):not([class*=" mt-"]) {
  margin-top: -1.5rem !important; }

.container-m--y:not([class^="mb-"]):not([class*=" mb-"]) {
  margin-bottom: -1.5rem !important; }

.rotate-90 {
  transform: rotate(90deg); }

.rotate-180 {
  transform: rotate(180deg); }

.rotate-270 {
  transform: rotate(270deg); }

.rotate--90 {
  transform: rotate(-90deg); }

.rotate--180 {
  transform: rotate(-180deg); }

.rotate--270 {
  transform: rotate(-270deg); }

.rotate-0 {
  transform: rotate(0deg) !important; }

.scaleX--1 {
  transform: scaleX(-1); }

.scaleY--1 {
  transform: scaleY(-1); }

.row-bordered {
  overflow: hidden; }
  .row-bordered > .col,
  .row-bordered > [class^="col-"],
  .row-bordered > [class*=" col-"],
  .row-bordered > [class^="col "],
  .row-bordered > [class*=" col "],
  .row-bordered > [class$=" col"],
  .row-bordered > [class="col"] {
    position: relative;
    padding-top: 1px; }
    .row-bordered > .col::before,
    .row-bordered > [class^="col-"]::before,
    .row-bordered > [class*=" col-"]::before,
    .row-bordered > [class^="col "]::before,
    .row-bordered > [class*=" col "]::before,
    .row-bordered > [class$=" col"]::before,
    .row-bordered > [class="col"]::before {
      content: "";
      position: absolute;
      right: 0;
      bottom: -1px;
      left: 0;
      display: block;
      height: 0;
      border-top: 1px solid rgba(24, 28, 33, 0.06); }
    .row-bordered > .col::after,
    .row-bordered > [class^="col-"]::after,
    .row-bordered > [class*=" col-"]::after,
    .row-bordered > [class^="col "]::after,
    .row-bordered > [class*=" col "]::after,
    .row-bordered > [class$=" col"]::after,
    .row-bordered > [class="col"]::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: -1px;
      display: block;
      width: 0;
      border-left: 1px solid rgba(24, 28, 33, 0.06); }
  .row-bordered.row-border-light > .col::before, .row-bordered.row-border-light > .col::after,
  .row-bordered.row-border-light > [class^="col-"]::before,
  .row-bordered.row-border-light > [class^="col-"]::after,
  .row-bordered.row-border-light > [class*=" col-"]::before,
  .row-bordered.row-border-light > [class*=" col-"]::after,
  .row-bordered.row-border-light > [class^="col "]::before,
  .row-bordered.row-border-light > [class^="col "]::after,
  .row-bordered.row-border-light > [class*=" col "]::before,
  .row-bordered.row-border-light > [class*=" col "]::after,
  .row-bordered.row-border-light > [class$=" col"]::before,
  .row-bordered.row-border-light > [class$=" col"]::after,
  .row-bordered.row-border-light > [class="col"]::before,
  .row-bordered.row-border-light > [class="col"]::after {
    border-color: rgba(24, 28, 33, 0.06); }

@media (min-width: 576px) {
  .w-sm-100 {
    width: 100% !important; }
  .w-sm-auto {
    width: auto !important; } }

@media (min-width: 768px) {
  .w-md-100 {
    width: 100% !important; }
  .w-md-auto {
    width: auto !important; } }

@media (min-width: 992px) {
  .w-lg-100 {
    width: 100% !important; }
  .w-lg-auto {
    width: auto !important; } }

@media (min-width: 1200px) {
  .w-xl-100 {
    width: 100% !important; }
  .w-xl-auto {
    width: auto !important; } }

.mr--0,
.mx--0 {
  margin-right: 0 !important; }

.ml--0,
.mx--0 {
  margin-left: 0 !important; }

.mr--1,
.mx--1 {
  margin-right: -0.25rem !important; }

.ml--1,
.mx--1 {
  margin-left: -0.25rem !important; }

.mr--2,
.mx--2 {
  margin-right: -0.5rem !important; }

.ml--2,
.mx--2 {
  margin-left: -0.5rem !important; }

.mr--3,
.mx--3 {
  margin-right: -1rem !important; }

.ml--3,
.mx--3 {
  margin-left: -1rem !important; }

.mr--4,
.mx--4 {
  margin-right: -1.5rem !important; }

.ml--4,
.mx--4 {
  margin-left: -1.5rem !important; }

.mr--5,
.mx--5 {
  margin-right: -3rem !important; }

.ml--5,
.mx--5 {
  margin-left: -3rem !important; }

@media (min-width: 576px) {
  .mr-sm--0,
  .mx-sm--0 {
    margin-right: 0 !important; }
  .ml-sm--0,
  .mx-sm--0 {
    margin-left: 0 !important; }
  .mr-sm--1,
  .mx-sm--1 {
    margin-right: -0.25rem !important; }
  .ml-sm--1,
  .mx-sm--1 {
    margin-left: -0.25rem !important; }
  .mr-sm--2,
  .mx-sm--2 {
    margin-right: -0.5rem !important; }
  .ml-sm--2,
  .mx-sm--2 {
    margin-left: -0.5rem !important; }
  .mr-sm--3,
  .mx-sm--3 {
    margin-right: -1rem !important; }
  .ml-sm--3,
  .mx-sm--3 {
    margin-left: -1rem !important; }
  .mr-sm--4,
  .mx-sm--4 {
    margin-right: -1.5rem !important; }
  .ml-sm--4,
  .mx-sm--4 {
    margin-left: -1.5rem !important; }
  .mr-sm--5,
  .mx-sm--5 {
    margin-right: -3rem !important; }
  .ml-sm--5,
  .mx-sm--5 {
    margin-left: -3rem !important; } }

@media (min-width: 768px) {
  .mr-md--0,
  .mx-md--0 {
    margin-right: 0 !important; }
  .ml-md--0,
  .mx-md--0 {
    margin-left: 0 !important; }
  .mr-md--1,
  .mx-md--1 {
    margin-right: -0.25rem !important; }
  .ml-md--1,
  .mx-md--1 {
    margin-left: -0.25rem !important; }
  .mr-md--2,
  .mx-md--2 {
    margin-right: -0.5rem !important; }
  .ml-md--2,
  .mx-md--2 {
    margin-left: -0.5rem !important; }
  .mr-md--3,
  .mx-md--3 {
    margin-right: -1rem !important; }
  .ml-md--3,
  .mx-md--3 {
    margin-left: -1rem !important; }
  .mr-md--4,
  .mx-md--4 {
    margin-right: -1.5rem !important; }
  .ml-md--4,
  .mx-md--4 {
    margin-left: -1.5rem !important; }
  .mr-md--5,
  .mx-md--5 {
    margin-right: -3rem !important; }
  .ml-md--5,
  .mx-md--5 {
    margin-left: -3rem !important; } }

@media (min-width: 992px) {
  .mr-lg--0,
  .mx-lg--0 {
    margin-right: 0 !important; }
  .ml-lg--0,
  .mx-lg--0 {
    margin-left: 0 !important; }
  .mr-lg--1,
  .mx-lg--1 {
    margin-right: -0.25rem !important; }
  .ml-lg--1,
  .mx-lg--1 {
    margin-left: -0.25rem !important; }
  .mr-lg--2,
  .mx-lg--2 {
    margin-right: -0.5rem !important; }
  .ml-lg--2,
  .mx-lg--2 {
    margin-left: -0.5rem !important; }
  .mr-lg--3,
  .mx-lg--3 {
    margin-right: -1rem !important; }
  .ml-lg--3,
  .mx-lg--3 {
    margin-left: -1rem !important; }
  .mr-lg--4,
  .mx-lg--4 {
    margin-right: -1.5rem !important; }
  .ml-lg--4,
  .mx-lg--4 {
    margin-left: -1.5rem !important; }
  .mr-lg--5,
  .mx-lg--5 {
    margin-right: -3rem !important; }
  .ml-lg--5,
  .mx-lg--5 {
    margin-left: -3rem !important; } }

@media (min-width: 1200px) {
  .mr-xl--0,
  .mx-xl--0 {
    margin-right: 0 !important; }
  .ml-xl--0,
  .mx-xl--0 {
    margin-left: 0 !important; }
  .mr-xl--1,
  .mx-xl--1 {
    margin-right: -0.25rem !important; }
  .ml-xl--1,
  .mx-xl--1 {
    margin-left: -0.25rem !important; }
  .mr-xl--2,
  .mx-xl--2 {
    margin-right: -0.5rem !important; }
  .ml-xl--2,
  .mx-xl--2 {
    margin-left: -0.5rem !important; }
  .mr-xl--3,
  .mx-xl--3 {
    margin-right: -1rem !important; }
  .ml-xl--3,
  .mx-xl--3 {
    margin-left: -1rem !important; }
  .mr-xl--4,
  .mx-xl--4 {
    margin-right: -1.5rem !important; }
  .ml-xl--4,
  .mx-xl--4 {
    margin-left: -1.5rem !important; }
  .mr-xl--5,
  .mx-xl--5 {
    margin-right: -3rem !important; }
  .ml-xl--5,
  .mx-xl--5 {
    margin-left: -3rem !important; } }

.flex-truncate {
  min-width: 0 !important; }

.flex-basis-100 {
  flex-basis: 100% !important; }

.flex-basis-auto {
  flex-basis: auto !important; }

@media (min-width: 576px) {
  .flex-basis-sm-100 {
    flex-basis: 100% !important; }
  .flex-basis-sm-auto {
    flex-basis: auto !important; } }

@media (min-width: 768px) {
  .flex-basis-md-100 {
    flex-basis: 100% !important; }
  .flex-basis-md-auto {
    flex-basis: auto !important; } }

@media (min-width: 992px) {
  .flex-basis-lg-100 {
    flex-basis: 100% !important; }
  .flex-basis-lg-auto {
    flex-basis: auto !important; } }

@media (min-width: 1200px) {
  .flex-basis-xl-100 {
    flex-basis: 100% !important; }
  .flex-basis-xl-auto {
    flex-basis: auto !important; } }

.d-flex,
.d-inline-flex,
.media,
.media > :not(.media-body),
.jumbotron,
.card {
  flex-shrink: 1; }

.ie-mh-1 {
  min-height: 1px; }

.text-dark {
  color: #4E5155 !important; }
  .text-dark[href]:hover {
    color: #3c8ae2 !important; }
  .text-dark[href]:focus:not(:hover) {
    color: #4E5155 !important; }

.text-muted[href]:hover, .text-muted[href]:focus {
  color: #8c8e90 !important; }

.text-light {
  color: #babbbc !important; }
  .text-light[href]:hover, .text-light[href]:focus {
    color: #8c8e90 !important; }

.text-lighter {
  color: #d1d2d3 !important; }
  .text-lighter[href]:hover, .text-lighter[href]:focus {
    color: #8c8e90 !important; }

.text-lightest {
  color: #e8e8e9 !important; }
  .text-lightest[href]:hover, .text-lightest[href]:focus {
    color: #8c8e90 !important; }

.bg-dark {
  background-color: rgba(24, 28, 33, 0.9) !important; }

a.bg-dark:hover, a.bg-dark:focus {
  background-color: rgba(24, 28, 33, 0.9) !important; }

.bg-light {
  background-color: rgba(24, 28, 33, 0.06) !important; }

a.bg-light:hover, a.bg-light:focus {
  background-color: rgba(24, 28, 33, 0.1) !important; }

.bg-lighter {
  background-color: rgba(24, 28, 33, 0.03) !important; }

a.bg-lighter:hover, a.bg-lighter:focus {
  background-color: rgba(24, 28, 33, 0.06) !important; }

.bg-lightest {
  background-color: rgba(24, 28, 33, 0.015) !important; }

a.bg-lightest:hover, a.bg-lightest:focus {
  background-color: rgba(24, 28, 33, 0.03) !important; }

.border-light {
  border-color: rgba(24, 28, 33, 0.06) !important; }

.border-dark {
  border-color: rgba(24, 28, 33, 0.9) !important; }

.border-transparent {
  border-color: transparent !important; }

body {
  -moz-font-feature-settings: "liga" on;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility; }

@media (min-width: 768px) {
  button.list-group-item {
    outline: none !important; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .card,
  .card-body,
  .media,
  .flex-column,
  .tab-content {
    min-height: 1px; }
  img {
    min-height: 1px;
    height: auto; } }

.container,
.container-fluid {
  padding-right: 1rem;
  padding-left: 1rem; }
  @media (min-width: 992px) {
    .container,
    .container-fluid {
      padding-right: 2rem;
      padding-left: 2rem; } }

.img-thumbnail {
  position: relative;
  display: block; }

.img-thumbnail img {
  z-index: 1; }

.img-thumbnail-content {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  display: block;
  opacity: 0;
  transition: all .2s ease-in-out;
  transform: translate(-50%, -50%); }
  .img-thumbnail:hover .img-thumbnail-content,
  .img-thumbnail:focus .img-thumbnail-content {
    opacity: 1; }

.img-thumbnail-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: block;
  transition: all .2s ease-in-out; }
  .img-thumbnail:not(:hover):not(:focus) .img-thumbnail-overlay {
    opacity: 0 !important; }

.img-thumbnail-shadow {
  transition: box-shadow .2s; }
  .img-thumbnail-shadow:hover, .img-thumbnail-shadow:focus {
    box-shadow: 0 5px 20px rgba(24, 28, 33, 0.4); }

.img-thumbnail-zoom-in {
  overflow: hidden; }
  .img-thumbnail-zoom-in img {
    transition: all .3s ease-in-out;
    transform: translate3d(0); }
  .img-thumbnail-zoom-in .img-thumbnail-content {
    transform: translate(-50%, -50%) scale(0.6); }
  .img-thumbnail-zoom-in:hover img, .img-thumbnail-zoom-in:focus img {
    transform: scale(1.1); }
  .img-thumbnail-zoom-in:hover .img-thumbnail-content, .img-thumbnail-zoom-in:focus .img-thumbnail-content {
    transform: translate(-50%, -50%) scale(1); }

.table .table-secondary,
.table .table-secondary > th,
.table .table-secondary > td {
  border-color: rgba(0, 0, 0, 0.035);
  background-color: #f3f5f7;
  color: #616263; }

.table-hover .table-secondary:hover,
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #f1f3f5; }

.table .table-success,
.table .table-success > th,
.table .table-success > td {
  border-color: rgba(0, 0, 0, 0.035);
  background-color: #e6f8f1;
  color: #5c6360; }

.table-hover .table-success:hover,
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #e4f6ef; }

.table .table-info,
.table .table-info > th,
.table .table-info > td {
  border-color: rgba(0, 0, 0, 0.035);
  background-color: #eaf9fb;
  color: #5e6464; }

.table-hover .table-info:hover,
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #e8f7f8; }

.table .table-warning,
.table .table-warning > th,
.table .table-warning > td {
  border-color: rgba(0, 0, 0, 0.035);
  background-color: #fffbee;
  color: #66645f; }

.table-hover .table-warning:hover,
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fcf8ec; }

.table .table-danger,
.table .table-danger > th,
.table .table-danger > td {
  border-color: rgba(0, 0, 0, 0.035);
  background-color: #fbeeed;
  color: #645f5f; }

.table-hover .table-danger:hover,
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f8eceb; }

.table .table-dark,
.table .table-dark > th,
.table .table-dark > td {
  border-color: rgba(0, 0, 0, 0.035);
  background-color: #e8e8e9;
  color: #5d5d5d; }

.table-hover .table-dark:hover,
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #e6e6e7; }

.table.table-fixed {
  table-layout: fixed; }

.table th {
  font-weight: 500; }

.table-responsive > .table {
  max-width: none; }

.table-active,
.table-active > th,
.table-active > td {
  border-color: rgba(24, 28, 33, 0.035);
  background-color: #f7f7f7; }

.table-hover .table-active:hover,
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: whitesmoke; }

.card-table {
  margin-bottom: 0; }

.card-table,
.card-table th,
.card-table td {
  border-color: rgba(24, 28, 33, 0.06); }

.card-table > thead:first-child > tr:first-child > td,
.card-table > thead:first-child > tr:first-child > th,
.card-table > tbody:first-child > tr:first-child > td,
.card-table > tbody:first-child > tr:first-child > th,
.card-table > tfoot:first-child > tr:first-child > td,
.card-table > tfoot:first-child > tr:first-child > th {
  border-top: 0; }

.card-table > thead > tr > td:first-child,
.card-table > thead > tr > th:first-child,
.card-table > tbody > tr > td:first-child,
.card-table > tbody > tr > th:first-child,
.card-table > tfoot > tr > td:first-child,
.card-table > tfoot > tr > th:first-child {
  padding-left: 1.5rem;
  border-left: 0; }

.card-table > thead > tr > td:last-child,
.card-table > thead > tr > th:last-child,
.card-table > tbody > tr > td:last-child,
.card-table > tbody > tr > th:last-child,
.card-table > tfoot > tr > td:last-child,
.card-table > tfoot > tr > th:last-child {
  padding-right: 1.5rem;
  border-right: 0; }

.card-condenced .card-table > thead > tr > td:first-child,
.card-condenced .card-table > thead > tr > th:first-child,
.card-condenced .card-table > tbody > tr > td:first-child,
.card-condenced .card-table > tbody > tr > th:first-child,
.card-condenced .card-table > tfoot > tr > td:first-child,
.card-condenced .card-table > tfoot > tr > th:first-child {
  padding-left: 1rem;
  border-left: 0; }

.card-condenced .card-table > thead > tr > td:last-child,
.card-condenced .card-table > thead > tr > th:last-child,
.card-condenced .card-table > tbody > tr > td:last-child,
.card-condenced .card-table > tbody > tr > th:last-child,
.card-condenced .card-table > tfoot > tr > td:last-child,
.card-condenced .card-table > tfoot > tr > th:last-child {
  padding-right: 1rem;
  border-right: 0; }

.btn {
  font-size: 0.894rem; }

.btn .badge {
  transition: all 0.2s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn .badge {
      transition: none; } }

label.btn {
  margin-bottom: 0; }

.btn-secondary {
  border-color: transparent;
  background: #8897AA;
  color: #fff; }
  .btn-secondary:hover {
    border-color: transparent;
    background: #818fa2;
    color: #fff; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 2px rgba(136, 151, 170, 0.4); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    border-color: transparent !important;
    background: #8897AA !important;
    box-shadow: none !important;
    color: #fff !important; }

.btn-secondary .badge {
  background: #fff;
  color: #8897AA; }

.btn-secondary:active,
.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  border-color: transparent;
  background: #768394;
  box-shadow: none; }

.btn-group .btn-secondary,
.input-group-prepend .btn-secondary,
.input-group-append .btn-secondary {
  border-right: 1px solid #768394;
  border-left: 1px solid #768394; }

.btn-outline-secondary {
  border-color: #8897AA;
  background: transparent;
  color: #8897AA; }
  .btn-outline-secondary:hover {
    border-color: transparent;
    background: #8897AA;
    color: #fff; }
    .btn-outline-secondary:hover .badge {
      background: #fff;
      color: #8897AA; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 2px rgba(136, 151, 170, 0.4); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    border-color: #8897AA !important;
    background: transparent !important;
    color: #8897AA !important; }

.btn-outline-secondary .badge {
  background: #8897AA;
  color: #fff; }

.btn-outline-secondary:active,
.btn-outline-secondary.active,
.show > .btn-outline-secondary.dropdown-toggle {
  border-color: transparent;
  background: #818fa2;
  color: #fff;
  box-shadow: none; }
  .btn-outline-secondary:active .badge,
  .btn-outline-secondary.active .badge,
  .show > .btn-outline-secondary.dropdown-toggle .badge {
    background: #fff;
    color: #8897AA; }

.btn-success {
  border-color: transparent;
  background: #02BC77;
  color: #fff; }
  .btn-success:hover {
    border-color: transparent;
    background: #02b371;
    color: #fff; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 2px rgba(2, 188, 119, 0.4); }
  .btn-success.disabled, .btn-success:disabled {
    border-color: transparent !important;
    background: #02BC77 !important;
    box-shadow: none !important;
    color: #fff !important; }

.btn-success .badge {
  background: #fff;
  color: #02BC77; }

.btn-success:active,
.btn-success.active,
.show > .btn-success.dropdown-toggle {
  border-color: transparent;
  background: #02a468;
  box-shadow: none; }

.btn-group .btn-success,
.input-group-prepend .btn-success,
.input-group-append .btn-success {
  border-right: 1px solid #02a468;
  border-left: 1px solid #02a468; }

.btn-outline-success {
  border-color: #02BC77;
  background: transparent;
  color: #02BC77; }
  .btn-outline-success:hover {
    border-color: transparent;
    background: #02BC77;
    color: #fff; }
    .btn-outline-success:hover .badge {
      background: #fff;
      color: #02BC77; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 2px rgba(2, 188, 119, 0.4); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    border-color: #02BC77 !important;
    background: transparent !important;
    color: #02BC77 !important; }

.btn-outline-success .badge {
  background: #02BC77;
  color: #fff; }

.btn-outline-success:active,
.btn-outline-success.active,
.show > .btn-outline-success.dropdown-toggle {
  border-color: transparent;
  background: #02b371;
  color: #fff;
  box-shadow: none; }
  .btn-outline-success:active .badge,
  .btn-outline-success.active .badge,
  .show > .btn-outline-success.dropdown-toggle .badge {
    background: #fff;
    color: #02BC77; }

.btn-info {
  border-color: transparent;
  background: #28c3d7;
  color: #fff; }
  .btn-info:hover {
    border-color: transparent;
    background: #26b9cc;
    color: #fff; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 2px rgba(40, 195, 215, 0.4); }
  .btn-info.disabled, .btn-info:disabled {
    border-color: transparent !important;
    background: #28c3d7 !important;
    box-shadow: none !important;
    color: #fff !important; }

.btn-info .badge {
  background: #fff;
  color: #28c3d7; }

.btn-info:active,
.btn-info.active,
.show > .btn-info.dropdown-toggle {
  border-color: transparent;
  background: #23aabb;
  box-shadow: none; }

.btn-group .btn-info,
.input-group-prepend .btn-info,
.input-group-append .btn-info {
  border-right: 1px solid #23aabb;
  border-left: 1px solid #23aabb; }

.btn-outline-info {
  border-color: #28c3d7;
  background: transparent;
  color: #28c3d7; }
  .btn-outline-info:hover {
    border-color: transparent;
    background: #28c3d7;
    color: #fff; }
    .btn-outline-info:hover .badge {
      background: #fff;
      color: #28c3d7; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 2px rgba(40, 195, 215, 0.4); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    border-color: #28c3d7 !important;
    background: transparent !important;
    color: #28c3d7 !important; }

.btn-outline-info .badge {
  background: #28c3d7;
  color: #fff; }

.btn-outline-info:active,
.btn-outline-info.active,
.show > .btn-outline-info.dropdown-toggle {
  border-color: transparent;
  background: #26b9cc;
  color: #fff;
  box-shadow: none; }
  .btn-outline-info:active .badge,
  .btn-outline-info.active .badge,
  .show > .btn-outline-info.dropdown-toggle .badge {
    background: #fff;
    color: #28c3d7; }

.btn-warning {
  border-color: transparent;
  background: #FFD950;
  color: #665720; }
  .btn-warning:hover {
    border-color: transparent;
    background: #f2ce4c;
    color: #665720; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 2px rgba(255, 217, 80, 0.4); }
  .btn-warning.disabled, .btn-warning:disabled {
    border-color: transparent !important;
    background: #FFD950 !important;
    box-shadow: none !important;
    color: #665720 !important; }

.btn-warning .badge {
  background: #665720;
  color: #FFD950; }

.btn-warning:active,
.btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  border-color: transparent;
  background: #debd46;
  box-shadow: none; }

.btn-group .btn-warning,
.input-group-prepend .btn-warning,
.input-group-append .btn-warning {
  border-right: 1px solid #debd46;
  border-left: 1px solid #debd46; }

.btn-outline-warning {
  border-color: #FFD950;
  background: transparent;
  color: #FFD950; }
  .btn-outline-warning:hover {
    border-color: transparent;
    background: #FFD950;
    color: #665720; }
    .btn-outline-warning:hover .badge {
      background: #665720;
      color: #FFD950; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 2px rgba(255, 217, 80, 0.4); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    border-color: #FFD950 !important;
    background: transparent !important;
    color: #FFD950 !important; }

.btn-outline-warning .badge {
  background: #FFD950;
  color: #fff; }

.btn-outline-warning:active,
.btn-outline-warning.active,
.show > .btn-outline-warning.dropdown-toggle {
  border-color: transparent;
  background: #f2ce4c;
  color: #665720;
  box-shadow: none; }
  .btn-outline-warning:active .badge,
  .btn-outline-warning.active .badge,
  .show > .btn-outline-warning.dropdown-toggle .badge {
    background: #665720;
    color: #FFD950; }

.btn-danger {
  border-color: transparent;
  background: #d9534f;
  color: #fff; }
  .btn-danger:hover {
    border-color: transparent;
    background: #ce4f4b;
    color: #fff; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.4); }
  .btn-danger.disabled, .btn-danger:disabled {
    border-color: transparent !important;
    background: #d9534f !important;
    box-shadow: none !important;
    color: #fff !important; }

.btn-danger .badge {
  background: #fff;
  color: #d9534f; }

.btn-danger:active,
.btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  border-color: transparent;
  background: #bd4845;
  box-shadow: none; }

.btn-group .btn-danger,
.input-group-prepend .btn-danger,
.input-group-append .btn-danger {
  border-right: 1px solid #bd4845;
  border-left: 1px solid #bd4845; }

.btn-outline-danger {
  border-color: #d9534f;
  background: transparent;
  color: #d9534f; }
  .btn-outline-danger:hover {
    border-color: transparent;
    background: #d9534f;
    color: #fff; }
    .btn-outline-danger:hover .badge {
      background: #fff;
      color: #d9534f; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.4); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    border-color: #d9534f !important;
    background: transparent !important;
    color: #d9534f !important; }

.btn-outline-danger .badge {
  background: #d9534f;
  color: #fff; }

.btn-outline-danger:active,
.btn-outline-danger.active,
.show > .btn-outline-danger.dropdown-toggle {
  border-color: transparent;
  background: #ce4f4b;
  color: #fff;
  box-shadow: none; }
  .btn-outline-danger:active .badge,
  .btn-outline-danger.active .badge,
  .show > .btn-outline-danger.dropdown-toggle .badge {
    background: #fff;
    color: #d9534f; }

.btn-light {
  border-color: transparent;
  background: rgba(24, 28, 33, 0.06);
  color: #0a0b0d; }
  .btn-light:hover {
    border-color: transparent;
    background: rgba(24, 28, 33, 0.11);
    color: #0a0b0d; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 2px rgba(24, 28, 33, 0.027); }
  .btn-light.disabled, .btn-light:disabled {
    border-color: transparent !important;
    background: rgba(24, 28, 33, 0.06) !important;
    box-shadow: none !important;
    color: #0a0b0d !important; }

.btn-light .badge {
  background: #0a0b0d;
  color: #f1f1f2; }

.btn-light:active,
.btn-light.active,
.show > .btn-light.dropdown-toggle {
  border-color: transparent;
  background: rgba(24, 28, 33, 0.19);
  box-shadow: none; }

.btn-group .btn-light,
.input-group-prepend .btn-light,
.input-group-append .btn-light {
  border-right: 1px solid rgba(24, 28, 33, 0.19);
  border-left: 1px solid rgba(24, 28, 33, 0.19); }

.btn-outline-light {
  border-color: rgba(24, 28, 33, 0.06);
  background: transparent;
  color: rgba(24, 28, 33, 0.06); }
  .btn-outline-light:hover {
    border-color: transparent;
    background: rgba(24, 28, 33, 0.06);
    color: #0a0b0d; }
    .btn-outline-light:hover .badge {
      background: #0a0b0d;
      color: #fff; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 2px rgba(24, 28, 33, 0.027); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    border-color: rgba(24, 28, 33, 0.06) !important;
    background: transparent !important;
    color: rgba(24, 28, 33, 0.06) !important; }

.btn-outline-light .badge {
  background: rgba(24, 28, 33, 0.06);
  color: #fff; }

.btn-outline-light:active,
.btn-outline-light.active,
.show > .btn-outline-light.dropdown-toggle {
  border-color: transparent;
  background: rgba(24, 28, 33, 0.11);
  color: #0a0b0d;
  box-shadow: none; }
  .btn-outline-light:active .badge,
  .btn-outline-light.active .badge,
  .show > .btn-outline-light.dropdown-toggle .badge {
    background: #0a0b0d;
    color: #fff; }

.btn-dark {
  border-color: transparent;
  background: rgba(24, 28, 33, 0.9);
  color: #fff; }
  .btn-dark:hover {
    border-color: transparent;
    background: rgba(24, 28, 33, 0.95);
    color: #fff; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 2px rgba(24, 28, 33, 0.405); }
  .btn-dark.disabled, .btn-dark:disabled {
    border-color: transparent !important;
    background: rgba(24, 28, 33, 0.9) !important;
    box-shadow: none !important;
    color: #fff !important; }

.btn-dark .badge {
  background: #fff;
  color: #2f3337; }

.btn-dark:active,
.btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  border-color: transparent;
  background: #181c21;
  box-shadow: none; }

.btn-group .btn-dark,
.input-group-prepend .btn-dark,
.input-group-append .btn-dark {
  border-right: 1px solid #181c21;
  border-left: 1px solid #181c21; }

.btn-outline-dark {
  border-color: rgba(24, 28, 33, 0.9);
  background: transparent;
  color: rgba(24, 28, 33, 0.9); }
  .btn-outline-dark:hover {
    border-color: transparent;
    background: rgba(24, 28, 33, 0.9);
    color: #fff; }
    .btn-outline-dark:hover .badge {
      background: #fff;
      color: #666666; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 2px rgba(24, 28, 33, 0.405); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    border-color: rgba(24, 28, 33, 0.9) !important;
    background: transparent !important;
    color: rgba(24, 28, 33, 0.9) !important; }

.btn-outline-dark .badge {
  background: rgba(24, 28, 33, 0.9);
  color: #fff; }

.btn-outline-dark:active,
.btn-outline-dark.active,
.show > .btn-outline-dark.dropdown-toggle {
  border-color: transparent;
  background: rgba(24, 28, 33, 0.95);
  color: #fff;
  box-shadow: none; }
  .btn-outline-dark:active .badge,
  .btn-outline-dark.active .badge,
  .show > .btn-outline-dark.dropdown-toggle .badge {
    background: #fff;
    color: #666666; }

.btn-default {
  border-color: rgba(24, 28, 33, 0.1);
  background: transparent;
  color: #4E5155; }
  .btn-default:hover {
    border-color: rgba(24, 28, 33, 0.1);
    background: rgba(24, 28, 33, 0.06);
    color: #4E5155; }
  .btn-default:focus, .btn-default.focus {
    box-shadow: 0 0 0 2px rgba(2, 3, 3, 0.05); }
  .btn-default.disabled, .btn-default:disabled {
    border-color: rgba(24, 28, 33, 0.1) !important;
    background: transparent !important;
    box-shadow: none !important;
    color: #4E5155 !important; }

.btn-default .badge {
  background: #4E5155;
  color: white; }

.btn-default:active,
.btn-default.active,
.show > .btn-default.dropdown-toggle {
  border-color: rgba(24, 28, 33, 0.1);
  background: rgba(24, 28, 33, 0.12);
  box-shadow: none; }

.btn-group .btn-default,
.input-group-prepend .btn-default,
.input-group-append .btn-default {
  border-right: 1px solid rgba(24, 28, 33, 0.12);
  border-left: 1px solid rgba(24, 28, 33, 0.12); }

.btn-outline-default {
  border-color: rgba(24, 28, 33, 0.1);
  background: transparent;
  color: rgba(24, 28, 33, 0.1); }
  .btn-outline-default:hover {
    border-color: transparent;
    background: rgba(24, 28, 33, 0.1);
    color: #4E5155; }
    .btn-outline-default:hover .badge {
      background: #4E5155;
      color: #fff; }
  .btn-outline-default:focus, .btn-outline-default.focus {
    box-shadow: 0 0 0 2px rgba(24, 28, 33, 0.045); }
  .btn-outline-default.disabled, .btn-outline-default:disabled {
    border-color: rgba(24, 28, 33, 0.1) !important;
    background: transparent !important;
    color: rgba(24, 28, 33, 0.1) !important; }

.btn-outline-default .badge {
  background: rgba(24, 28, 33, 0.1);
  color: #fff; }

.btn-outline-default:active,
.btn-outline-default.active,
.show > .btn-outline-default.dropdown-toggle {
  border-color: transparent;
  background: rgba(24, 28, 33, 0.15);
  color: #4E5155;
  box-shadow: none; }
  .btn-outline-default:active .badge,
  .btn-outline-default.active .badge,
  .show > .btn-outline-default.dropdown-toggle .badge {
    background: #4E5155;
    color: #fff; }

.btn-xl, .btn-group-xl > .btn {
  padding: 0.875rem 2.125rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.25rem; }

.btn-lg {
  font-size: 1rem; }

.btn-sm {
  font-size: 0.75rem; }

.btn-xs, .btn-group-xs > .btn {
  padding: 0 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem; }

.btn-round {
  border-radius: 60rem !important; }

.btn.btn-link {
  font-size: inherit; }

.icon-btn {
  width: calc(2.25276rem + 2px) !important;
  padding: 0;
  line-height: 2.25276rem; }
  .icon-btn.btn-xl, .btn-group-xl > .icon-btn.btn {
    width: calc(3.625rem + 2px) !important;
    line-height: 3.625rem; }
  .icon-btn.btn-lg {
    width: calc(3rem + 2px) !important;
    line-height: 3rem; }
  .icon-btn.btn-sm {
    width: calc(1.501rem + 2px) !important;
    line-height: 1.501rem; }
  .icon-btn.btn-xs, .btn-group-xs > .icon-btn.btn {
    width: calc(1.125rem + 2px) !important;
    line-height: 1.125rem; }

.btn.borderless:not(.active):not(:active):not(:hover):not(:focus),
:not(.show) > .btn.borderless.dropdown-toggle:not(:hover):not(:focus) {
  border-color: transparent !important;
  box-shadow: none !important; }

:not(.btn-group) > .btn-group > .btn:not(.btn-default):not([class*="btn-outline-"]):first-child,
.btn-group > .btn-group:first-child > .btn:not(.btn-default):not([class*="btn-outline-"]):first-child,
.input-group-prepend > .btn:not(.btn-default):not([class*="btn-outline-"]):first-child,
.input-group-append > .btn:not(.btn-default):not([class*="btn-outline-"]):first-child {
  border-left-color: transparent !important; }

:not(.btn-group) > .btn-group > .btn:not(.btn-default):not([class*="btn-outline-"]):last-child,
.btn-group > .btn-group:last-child > .btn:not(.btn-default):not([class*="btn-outline-"]):last-child,
:not(.btn-group) > .btn-group > .dropdown-toggle:not(.btn-default):not([class*="btn-outline-"]),
.btn-group > .btn-group:last-child > .dropdown-toggle:not(.btn-default):not([class*="btn-outline-"]),
.input-group-prepend > .btn:not(.btn-default):not([class*="btn-outline-"]):last-child,
.input-group-append > .btn:not(.btn-default):not([class*="btn-outline-"]):last-child,
.input-group-prepend > .dropdown-toggle:not(.btn-default):not([class*="btn-outline-"]),
.input-group-append > .dropdown-toggle:not(.btn-default):not([class*="btn-outline-"]) {
  border-right-color: transparent !important; }

.btn-group-sm > .btn {
  font-size: 0.75rem; }

.btn-group-lg > .btn {
  font-size: 1rem; }

.dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split,
.input-group-lg .btn + .dropdown-toggle-split,
.btn-xl + .dropdown-toggle-split,
.btn-group-xl > .btn + .dropdown-toggle-split,
.btn-group-xl > .btn + .dropdown-toggle-split {
  padding-right: .7em;
  padding-left: .7em; }

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split,
.input-group-sm .btn + .dropdown-toggle-split {
  padding-right: .6em;
  padding-left: .6em; }

.btn-xs + .dropdown-toggle-split, .btn-group-xs > .btn + .dropdown-toggle-split,
.btn-group-xs > .btn + .dropdown-toggle-split {
  padding-right: .5em;
  padding-left: .5em; }

.badge-secondary {
  background-color: #8897AA;
  color: #fff; }

.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  background-color: #818fa2;
  color: #fff;
  text-decoration: none; }

.btn .badge-secondary {
  background-color: #8897AA !important;
  color: #fff !important; }

.badge-outline-secondary {
  background-color: transparent;
  box-shadow: 0 0 0 1px #8897AA inset;
  color: #8897AA; }

.badge-outline-secondary[href]:hover, .badge-outline-secondary[href]:focus {
  color: #8897AA;
  text-decoration: none; }

.btn .badge-outline-secondary {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #8897AA inset !important;
  color: #8897AA !important; }

.badge-success {
  background-color: #02BC77;
  color: #fff; }

.badge-success[href]:hover, .badge-success[href]:focus {
  background-color: #02b371;
  color: #fff;
  text-decoration: none; }

.btn .badge-success {
  background-color: #02BC77 !important;
  color: #fff !important; }

.badge-outline-success {
  background-color: transparent;
  box-shadow: 0 0 0 1px #02BC77 inset;
  color: #02BC77; }

.badge-outline-success[href]:hover, .badge-outline-success[href]:focus {
  color: #02BC77;
  text-decoration: none; }

.btn .badge-outline-success {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #02BC77 inset !important;
  color: #02BC77 !important; }

.badge-info {
  background-color: #28c3d7;
  color: #fff; }

.badge-info[href]:hover, .badge-info[href]:focus {
  background-color: #26b9cc;
  color: #fff;
  text-decoration: none; }

.btn .badge-info {
  background-color: #28c3d7 !important;
  color: #fff !important; }

.badge-outline-info {
  background-color: transparent;
  box-shadow: 0 0 0 1px #28c3d7 inset;
  color: #28c3d7; }

.badge-outline-info[href]:hover, .badge-outline-info[href]:focus {
  color: #28c3d7;
  text-decoration: none; }

.btn .badge-outline-info {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #28c3d7 inset !important;
  color: #28c3d7 !important; }

.badge-warning {
  background-color: #FFD950;
  color: #665720; }

.badge-warning[href]:hover, .badge-warning[href]:focus {
  background-color: #f2ce4c;
  color: #665720;
  text-decoration: none; }

.btn .badge-warning {
  background-color: #FFD950 !important;
  color: #665720 !important; }

.badge-outline-warning {
  background-color: transparent;
  box-shadow: 0 0 0 1px #FFD950 inset;
  color: #FFD950; }

.badge-outline-warning[href]:hover, .badge-outline-warning[href]:focus {
  color: #FFD950;
  text-decoration: none; }

.btn .badge-outline-warning {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #FFD950 inset !important;
  color: #FFD950 !important; }

.badge-danger {
  background-color: #d9534f;
  color: #fff; }

.badge-danger[href]:hover, .badge-danger[href]:focus {
  background-color: #ce4f4b;
  color: #fff;
  text-decoration: none; }

.btn .badge-danger {
  background-color: #d9534f !important;
  color: #fff !important; }

.badge-outline-danger {
  background-color: transparent;
  box-shadow: 0 0 0 1px #d9534f inset;
  color: #d9534f; }

.badge-outline-danger[href]:hover, .badge-outline-danger[href]:focus {
  color: #d9534f;
  text-decoration: none; }

.btn .badge-outline-danger {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #d9534f inset !important;
  color: #d9534f !important; }

.badge-dark {
  background-color: rgba(24, 28, 33, 0.9);
  color: #fff; }

.badge-dark[href]:hover, .badge-dark[href]:focus {
  background-color: rgba(24, 28, 33, 0.93);
  color: #fff;
  text-decoration: none; }

.btn .badge-dark {
  background-color: rgba(24, 28, 33, 0.9) !important;
  color: #fff !important; }

.badge-outline-dark {
  background-color: transparent;
  box-shadow: 0 0 0 1px rgba(24, 28, 33, 0.9) inset;
  color: rgba(24, 28, 33, 0.9); }

.badge-outline-dark[href]:hover, .badge-outline-dark[href]:focus {
  color: rgba(24, 28, 33, 0.9);
  text-decoration: none; }

.btn .badge-outline-dark {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px rgba(24, 28, 33, 0.9) inset !important;
  color: rgba(24, 28, 33, 0.9) !important; }

.badge-default {
  background-color: rgba(24, 28, 33, 0.1);
  color: #4E5155; }

.badge-default[href]:hover, .badge-default[href]:focus {
  background-color: rgba(24, 28, 33, 0.13);
  color: #4E5155;
  text-decoration: none; }

.btn .badge-default {
  background-color: rgba(24, 28, 33, 0.1) !important;
  color: #4E5155 !important; }

.badge-outline-default {
  background-color: transparent;
  box-shadow: 0 0 0 1px rgba(24, 28, 33, 0.1) inset;
  color: #4E5155; }

.badge-outline-default[href]:hover, .badge-outline-default[href]:focus {
  color: #4E5155;
  text-decoration: none; }

.btn .badge-outline-default {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px rgba(24, 28, 33, 0.1) inset !important;
  color: #4E5155 !important; }

.badge.badge-dot {
  display: inline-block;
  margin: 0;
  padding: 0;
  width: .5em;
  height: .5em;
  border-radius: 50%;
  vertical-align: middle; }

.badge.indicator {
  position: absolute;
  top: auto;
  display: inline-block;
  margin: 0;
  transform: translate(-50%, 25%); }
  .badge.indicator:not(.badge-dot) {
    padding: 0 .2em;
    font-size: .6em;
    line-height: 1.2em;
    transform: translate(-50%, -10%); }

[data-trigger=hover] {
  outline: 0; }

.dropdown-menu {
  margin: 0.125rem 0;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.09); }
  .mega-dropdown > .dropdown-menu {
    left: 0 !important;
    right: 0 !important; }

.dropdown-item {
  line-height: 1.54; }

.dropdown-menu .badge[class^="float-"],
.dropdown-menu .badge[class*=" float-"] {
  position: relative;
  top: .071em; }

.dropdown-toggle.hide-arrow::before, .dropdown-toggle.hide-arrow::after,
.dropdown-toggle-hide-arrow > .dropdown-toggle::before,
.dropdown-toggle-hide-arrow > .dropdown-toggle::after {
  display: none !important; }

.dropdown-menu > li:not(.dropdown-item) > a:not(.dropdown-item) {
  display: block;
  clear: both;
  padding: 0.438rem 1.25rem;
  width: 100%;
  border: 0;
  background: none;
  color: #4E5155;
  text-align: inherit;
  white-space: nowrap;
  font-weight: 400;
  line-height: 1.54; }
  .dropdown-menu > li:not(.dropdown-item) > a:not(.dropdown-item):hover, .dropdown-menu > li:not(.dropdown-item) > a:not(.dropdown-item):focus {
    background-color: rgba(24, 28, 33, 0.03);
    color: #4E5155;
    text-decoration: none; }

.dropdown-menu > li:not(.dropdown-item).active > a:not(.dropdown-item),
.dropdown-menu > li:not(.dropdown-item) > a:not(.dropdown-item):active {
  background-color: #6610f2;
  color: #fff;
  text-decoration: none; }

.dropdown-menu > li:not(.dropdown-item).disabled > a:not(.dropdown-item) {
  background-color: transparent;
  color: #d1d2d3; }

.dropdown-toggle::after, .dropdown-toggle::before {
  vertical-align: middle !important; }

.dropdown-toggle::after,
.dropup .dropdown-toggle::after,
.dropright .dropdown-toggle::after {
  margin-left: .5em; }

.dropleft .dropdown-toggle::before {
  margin-right: .5em; }

.dropdown-toggle-split::after {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.dropdown-menu .dropdown-toggle {
  position: relative; }
  .dropdown-menu .dropdown-toggle::after {
    display: none; }

.dropdown-menu .dropdown-toggle > .dropdown-item {
  position: relative;
  cursor: default; }

@media (max-width: 767px) {
  .dropdown-menu .dropdown-menu {
    position: static;
    display: block;
    margin: 0;
    padding-left: 1.25rem;
    width: 100%;
    border-width: 0;
    border-radius: 0;
    box-shadow: none; } }

@media (min-width: 768px) {
  .dropdown-menu .dropdown-toggle > .dropdown-item::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0.625rem;
    display: block;
    width: .375rem;
    height: .375rem;
    border: 1px solid #a3a4a6;
    border-top: 0;
    border-left: 0;
    transform: rotate(-45deg) translate(0, -50%); }
  .dropdown-menu .dropdown-toggle:hover > .dropdown-item:not(.disabled) {
    background: rgba(24, 28, 33, 0.03);
    color: #4E5155; }
    .dropdown-menu .dropdown-toggle:hover > .dropdown-item:not(.disabled) + .dropdown-menu {
      display: block; }
  .dropdown-menu .dropdown-menu {
    top: 0;
    left: 100%; }
  .dropdown-menu-right .dropdown-menu {
    right: 100%;
    left: auto; } }

.nav-link {
  line-height: 1.54; }
  .nav-link.disabled {
    border-color: transparent !important;
    background: transparent !important;
    color: #d1d2d3 !important; }

.nav-tabs .nav-link:not(.active),
.nav-pills .nav-link:not(.active) {
  color: #8c8e90; }
  .nav-tabs .nav-link:not(.active):hover, .nav-tabs .nav-link:not(.active):focus,
  .nav-pills .nav-link:not(.active):hover,
  .nav-pills .nav-link:not(.active):focus {
    color: #4E5155; }

.nav-tabs:not(.nav-fill):not(.nav-justified) .nav-link,
.nav-pills:not(.nav-fill):not(.nav-justified) .nav-link {
  margin-right: 0.125rem; }

.nav-tabs .nav-link .badge,
.nav-pills .nav-link .badge {
  position: relative;
  top: -1px; }

.nav-tabs .nav-item.show .nav-link:not(.active),
.nav-pills .nav-item.show .nav-link:not(.active) {
  background-color: transparent; }

.nav-tabs .nav-link {
  background-clip: padding-box; }
  .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-bottom-color: transparent; }
  .nav-tabs .nav-link.active {
    border-bottom-color: #fff; }

.nav-tabs.tabs-alt .nav-link,
.tabs-alt > .nav-tabs .nav-link {
  border-width: 0 !important;
  border-radius: 0 !important;
  background-color: transparent !important; }

.nav-lg > .nav .nav-link,
.nav-lg.nav .nav-link {
  padding: 0.875rem 1.3125rem;
  font-size: 1rem;
  line-height: 1.5; }

.nav-sm > .nav .nav-link,
.nav-sm.nav .nav-link {
  padding: 0.3125rem 0.875rem;
  font-size: 0.75rem;
  line-height: 1.5; }

.nav-tabs-top,
.nav-tabs-right,
.nav-tabs-bottom,
.nav-tabs-left {
  display: flex; }
  .nav-tabs-top > .nav,
  .nav-tabs-top > div > .nav,
  .nav-tabs-right > .nav,
  .nav-tabs-right > div > .nav,
  .nav-tabs-bottom > .nav,
  .nav-tabs-bottom > div > .nav,
  .nav-tabs-left > .nav,
  .nav-tabs-left > div > .nav {
    position: relative;
    z-index: 1;
    border: 0; }
  .nav-tabs-top > .nav .nav-link:hover, .nav-tabs-top > .nav .nav-link:focus,
  .nav-tabs-top > div > .nav .nav-link:hover,
  .nav-tabs-top > div > .nav .nav-link:focus,
  .nav-tabs-right > .nav .nav-link:hover,
  .nav-tabs-right > .nav .nav-link:focus,
  .nav-tabs-right > div > .nav .nav-link:hover,
  .nav-tabs-right > div > .nav .nav-link:focus,
  .nav-tabs-bottom > .nav .nav-link:hover,
  .nav-tabs-bottom > .nav .nav-link:focus,
  .nav-tabs-bottom > div > .nav .nav-link:hover,
  .nav-tabs-bottom > div > .nav .nav-link:focus,
  .nav-tabs-left > .nav .nav-link:hover,
  .nav-tabs-left > .nav .nav-link:focus,
  .nav-tabs-left > div > .nav .nav-link:hover,
  .nav-tabs-left > div > .nav .nav-link:focus {
    border-color: rgba(24, 28, 33, 0.06); }
  .nav-tabs-top > .nav .nav-link.active,
  .nav-tabs-top > div > .nav .nav-link.active,
  .nav-tabs-right > .nav .nav-link.active,
  .nav-tabs-right > div > .nav .nav-link.active,
  .nav-tabs-bottom > .nav .nav-link.active,
  .nav-tabs-bottom > div > .nav .nav-link.active,
  .nav-tabs-left > .nav .nav-link.active,
  .nav-tabs-left > div > .nav .nav-link.active {
    border-color: rgba(24, 28, 33, 0.06); }

.nav-tabs-right,
.nav-tabs-left {
  align-items: stretch; }
  .nav-tabs-right > .nav,
  .nav-tabs-right > div > .nav,
  .nav-tabs-left > .nav,
  .nav-tabs-left > div > .nav {
    flex-direction: column;
    flex-grow: 0; }
  .nav-tabs-right > .nav .nav-link,
  .nav-tabs-right > div > .nav .nav-link,
  .nav-tabs-left > .nav .nav-link,
  .nav-tabs-left > div > .nav .nav-link {
    margin: 0 0 0.125rem 0 !important; }
  .nav-tabs-right > .tab-content,
  .nav-tabs-left > .tab-content {
    flex-grow: 1; }

.nav-tabs-top {
  flex-direction: column; }
  .nav-tabs-top > .nav .nav-link:hover, .nav-tabs-top > .nav .nav-link:focus,
  .nav-tabs-top > div > .nav .nav-link:hover,
  .nav-tabs-top > div > .nav .nav-link:focus {
    border-bottom-color: transparent; }
  .nav-tabs-top > .nav .nav-link.active,
  .nav-tabs-top > div > .nav .nav-link.active {
    border-bottom-color: #fff; }

.nav-tabs-right {
  flex-direction: row-reverse; }
  .nav-tabs-right > .nav .nav-item,
  .nav-tabs-right > div > .nav .nav-item {
    margin-bottom: 0;
    margin-left: -1px; }
  .nav-tabs-right > .nav .nav-link,
  .nav-tabs-right > div > .nav .nav-link {
    border-radius: 0 0.25rem 0.25rem 0; }
    .nav-tabs-right > .nav .nav-link:hover, .nav-tabs-right > .nav .nav-link:focus,
    .nav-tabs-right > div > .nav .nav-link:hover,
    .nav-tabs-right > div > .nav .nav-link:focus {
      border-left-color: transparent; }
    .nav-tabs-right > .nav .nav-link.active,
    .nav-tabs-right > div > .nav .nav-link.active {
      border-left-color: #fff; }

.nav-tabs-bottom {
  flex-direction: column-reverse; }
  .nav-tabs-bottom > .nav .nav-item,
  .nav-tabs-bottom > div > .nav .nav-item {
    margin-top: -1px;
    margin-bottom: 0; }
  .nav-tabs-bottom > .nav .nav-link,
  .nav-tabs-bottom > div > .nav .nav-link {
    border-radius: 0 0 0.25rem 0.25rem; }
    .nav-tabs-bottom > .nav .nav-link:hover, .nav-tabs-bottom > .nav .nav-link:focus,
    .nav-tabs-bottom > div > .nav .nav-link:hover,
    .nav-tabs-bottom > div > .nav .nav-link:focus {
      border-top-color: transparent; }
    .nav-tabs-bottom > .nav .nav-link.active,
    .nav-tabs-bottom > div > .nav .nav-link.active {
      border-top-color: #fff; }

.nav-tabs-left > .nav .nav-item,
.nav-tabs-left > div > .nav .nav-item {
  margin-right: -1px;
  margin-bottom: 0; }

.nav-tabs-left > .nav .nav-link,
.nav-tabs-left > div > .nav .nav-link {
  border-radius: 0.25rem 0 0 0.25rem; }
  .nav-tabs-left > .nav .nav-link:hover, .nav-tabs-left > .nav .nav-link:focus,
  .nav-tabs-left > div > .nav .nav-link:hover,
  .nav-tabs-left > div > .nav .nav-link:focus {
    border-right-color: transparent; }
  .nav-tabs-left > .nav .nav-link.active,
  .nav-tabs-left > div > .nav .nav-link.active {
    border-right-color: #fff; }

.nav-tabs-top > .tab-content,
.nav-tabs-right > .tab-content,
.nav-tabs-bottom > .tab-content,
.nav-tabs-left > .tab-content {
  flex-shrink: 1;
  border: 1px solid rgba(24, 28, 33, 0.06);
  background: #fff;
  background-clip: padding-box;
  box-shadow: 0 1px 4px rgba(24, 28, 33, 0.009); }

.nav-tabs-top > .tab-content {
  border-radius: 0 0 0.25rem 0.25rem; }

.nav-tabs-right > .tab-content {
  border-radius: 0.25rem 0 0 0.25rem; }

.nav-tabs-bottom > .tab-content {
  border-radius: 0.25rem 0.25rem 0 0; }

.nav-tabs-left > .tab-content {
  border-radius: 0 0.25rem 0.25rem 0; }

@media (max-width: 575px) {
  .nav-responsive-sm > .nav,
  .nav-responsive-sm > div > .nav {
    flex-direction: column;
    border: 0; }
    .nav-responsive-sm > .nav .nav-item,
    .nav-responsive-sm > div > .nav .nav-item {
      flex-basis: auto; }
    .nav-responsive-sm > .nav.card-header-tabs, .nav-responsive-sm > .nav.card-header-pills,
    .nav-responsive-sm > div > .nav.card-header-tabs,
    .nav-responsive-sm > div > .nav.card-header-pills {
      margin-bottom: -0.125rem; }
  .nav-responsive-sm > .nav-tabs .nav-item,
  .nav-responsive-sm > .nav-pills .nav-item,
  .nav-responsive-sm > div > .nav-tabs .nav-item,
  .nav-responsive-sm > div > .nav-pills .nav-item {
    margin: 0 !important; }
  .nav-responsive-sm > .nav-tabs .nav-link,
  .nav-responsive-sm > .nav-pills .nav-link,
  .nav-responsive-sm > div > .nav-tabs .nav-link,
  .nav-responsive-sm > div > .nav-pills .nav-link {
    margin: 0 0 0.125rem 0 !important; }
  .nav-responsive-sm > .nav-tabs:not(.tabs-alt) .nav-link,
  .nav-responsive-sm > div > .nav-tabs:not(.tabs-alt) .nav-link {
    border-radius: 0.25rem !important; }
    .nav-responsive-sm > .nav-tabs:not(.tabs-alt) .nav-link:hover, .nav-responsive-sm > .nav-tabs:not(.tabs-alt) .nav-link:focus,
    .nav-responsive-sm > div > .nav-tabs:not(.tabs-alt) .nav-link:hover,
    .nav-responsive-sm > div > .nav-tabs:not(.tabs-alt) .nav-link:focus {
      border-color: rgba(24, 28, 33, 0.06) !important; }
  .nav-responsive-sm > .nav-tabs:not(.tabs-alt) .nav-link.active,
  .nav-responsive-sm > div > .nav-tabs:not(.tabs-alt) .nav-link.active {
    border-color: rgba(24, 28, 33, 0.06) !important; }
  .nav-responsive-sm.nav-tabs-top > .tab-content,
  .nav-responsive-sm.nav-tabs-right > .tab-content,
  .nav-responsive-sm.nav-tabs-bottom > .tab-content,
  .nav-responsive-sm.nav-tabs-left > .tab-content {
    border-radius: 0.25rem !important; }
  .nav-responsive-sm.nav-tabs-bottom, .nav-responsive-sm.nav-tabs-right, .nav-responsive-sm.nav-tabs-left {
    flex-direction: column !important; } }

@media (max-width: 767px) {
  .nav-responsive-md > .nav,
  .nav-responsive-md > div > .nav {
    flex-direction: column;
    border: 0; }
    .nav-responsive-md > .nav .nav-item,
    .nav-responsive-md > div > .nav .nav-item {
      flex-basis: auto; }
    .nav-responsive-md > .nav.card-header-tabs, .nav-responsive-md > .nav.card-header-pills,
    .nav-responsive-md > div > .nav.card-header-tabs,
    .nav-responsive-md > div > .nav.card-header-pills {
      margin-bottom: -0.125rem; }
  .nav-responsive-md > .nav-tabs .nav-item,
  .nav-responsive-md > .nav-pills .nav-item,
  .nav-responsive-md > div > .nav-tabs .nav-item,
  .nav-responsive-md > div > .nav-pills .nav-item {
    margin: 0 !important; }
  .nav-responsive-md > .nav-tabs .nav-link,
  .nav-responsive-md > .nav-pills .nav-link,
  .nav-responsive-md > div > .nav-tabs .nav-link,
  .nav-responsive-md > div > .nav-pills .nav-link {
    margin: 0 0 0.125rem 0 !important; }
  .nav-responsive-md > .nav-tabs:not(.tabs-alt) .nav-link,
  .nav-responsive-md > div > .nav-tabs:not(.tabs-alt) .nav-link {
    border-radius: 0.25rem !important; }
    .nav-responsive-md > .nav-tabs:not(.tabs-alt) .nav-link:hover, .nav-responsive-md > .nav-tabs:not(.tabs-alt) .nav-link:focus,
    .nav-responsive-md > div > .nav-tabs:not(.tabs-alt) .nav-link:hover,
    .nav-responsive-md > div > .nav-tabs:not(.tabs-alt) .nav-link:focus {
      border-color: rgba(24, 28, 33, 0.06) !important; }
  .nav-responsive-md > .nav-tabs:not(.tabs-alt) .nav-link.active,
  .nav-responsive-md > div > .nav-tabs:not(.tabs-alt) .nav-link.active {
    border-color: rgba(24, 28, 33, 0.06) !important; }
  .nav-responsive-md.nav-tabs-top > .tab-content,
  .nav-responsive-md.nav-tabs-right > .tab-content,
  .nav-responsive-md.nav-tabs-bottom > .tab-content,
  .nav-responsive-md.nav-tabs-left > .tab-content {
    border-radius: 0.25rem !important; }
  .nav-responsive-md.nav-tabs-bottom, .nav-responsive-md.nav-tabs-right, .nav-responsive-md.nav-tabs-left {
    flex-direction: column !important; } }

@media (max-width: 991px) {
  .nav-responsive-lg > .nav,
  .nav-responsive-lg > div > .nav {
    flex-direction: column;
    border: 0; }
    .nav-responsive-lg > .nav .nav-item,
    .nav-responsive-lg > div > .nav .nav-item {
      flex-basis: auto; }
    .nav-responsive-lg > .nav.card-header-tabs, .nav-responsive-lg > .nav.card-header-pills,
    .nav-responsive-lg > div > .nav.card-header-tabs,
    .nav-responsive-lg > div > .nav.card-header-pills {
      margin-bottom: -0.125rem; }
  .nav-responsive-lg > .nav-tabs .nav-item,
  .nav-responsive-lg > .nav-pills .nav-item,
  .nav-responsive-lg > div > .nav-tabs .nav-item,
  .nav-responsive-lg > div > .nav-pills .nav-item {
    margin: 0 !important; }
  .nav-responsive-lg > .nav-tabs .nav-link,
  .nav-responsive-lg > .nav-pills .nav-link,
  .nav-responsive-lg > div > .nav-tabs .nav-link,
  .nav-responsive-lg > div > .nav-pills .nav-link {
    margin: 0 0 0.125rem 0 !important; }
  .nav-responsive-lg > .nav-tabs:not(.tabs-alt) .nav-link,
  .nav-responsive-lg > div > .nav-tabs:not(.tabs-alt) .nav-link {
    border-radius: 0.25rem !important; }
    .nav-responsive-lg > .nav-tabs:not(.tabs-alt) .nav-link:hover, .nav-responsive-lg > .nav-tabs:not(.tabs-alt) .nav-link:focus,
    .nav-responsive-lg > div > .nav-tabs:not(.tabs-alt) .nav-link:hover,
    .nav-responsive-lg > div > .nav-tabs:not(.tabs-alt) .nav-link:focus {
      border-color: rgba(24, 28, 33, 0.06) !important; }
  .nav-responsive-lg > .nav-tabs:not(.tabs-alt) .nav-link.active,
  .nav-responsive-lg > div > .nav-tabs:not(.tabs-alt) .nav-link.active {
    border-color: rgba(24, 28, 33, 0.06) !important; }
  .nav-responsive-lg.nav-tabs-top > .tab-content,
  .nav-responsive-lg.nav-tabs-right > .tab-content,
  .nav-responsive-lg.nav-tabs-bottom > .tab-content,
  .nav-responsive-lg.nav-tabs-left > .tab-content {
    border-radius: 0.25rem !important; }
  .nav-responsive-lg.nav-tabs-bottom, .nav-responsive-lg.nav-tabs-right, .nav-responsive-lg.nav-tabs-left {
    flex-direction: column !important; } }

@media (max-width: 1199px) {
  .nav-responsive-xl > .nav,
  .nav-responsive-xl > div > .nav {
    flex-direction: column;
    border: 0; }
    .nav-responsive-xl > .nav .nav-item,
    .nav-responsive-xl > div > .nav .nav-item {
      flex-basis: auto; }
    .nav-responsive-xl > .nav.card-header-tabs, .nav-responsive-xl > .nav.card-header-pills,
    .nav-responsive-xl > div > .nav.card-header-tabs,
    .nav-responsive-xl > div > .nav.card-header-pills {
      margin-bottom: -0.125rem; }
  .nav-responsive-xl > .nav-tabs .nav-item,
  .nav-responsive-xl > .nav-pills .nav-item,
  .nav-responsive-xl > div > .nav-tabs .nav-item,
  .nav-responsive-xl > div > .nav-pills .nav-item {
    margin: 0 !important; }
  .nav-responsive-xl > .nav-tabs .nav-link,
  .nav-responsive-xl > .nav-pills .nav-link,
  .nav-responsive-xl > div > .nav-tabs .nav-link,
  .nav-responsive-xl > div > .nav-pills .nav-link {
    margin: 0 0 0.125rem 0 !important; }
  .nav-responsive-xl > .nav-tabs:not(.tabs-alt) .nav-link,
  .nav-responsive-xl > div > .nav-tabs:not(.tabs-alt) .nav-link {
    border-radius: 0.25rem !important; }
    .nav-responsive-xl > .nav-tabs:not(.tabs-alt) .nav-link:hover, .nav-responsive-xl > .nav-tabs:not(.tabs-alt) .nav-link:focus,
    .nav-responsive-xl > div > .nav-tabs:not(.tabs-alt) .nav-link:hover,
    .nav-responsive-xl > div > .nav-tabs:not(.tabs-alt) .nav-link:focus {
      border-color: rgba(24, 28, 33, 0.06) !important; }
  .nav-responsive-xl > .nav-tabs:not(.tabs-alt) .nav-link.active,
  .nav-responsive-xl > div > .nav-tabs:not(.tabs-alt) .nav-link.active {
    border-color: rgba(24, 28, 33, 0.06) !important; }
  .nav-responsive-xl.nav-tabs-top > .tab-content,
  .nav-responsive-xl.nav-tabs-right > .tab-content,
  .nav-responsive-xl.nav-tabs-bottom > .tab-content,
  .nav-responsive-xl.nav-tabs-left > .tab-content {
    border-radius: 0.25rem !important; }
  .nav-responsive-xl.nav-tabs-bottom, .nav-responsive-xl.nav-tabs-right, .nav-responsive-xl.nav-tabs-left {
    flex-direction: column !important; } }

.page-item + .page-item .page-link,
.pagination li + li > a:not(.page-link) {
  margin-left: 0.1875rem; }

.page-link,
.page-link > a {
  min-width: calc(2.144rem + 2px);
  text-align: center;
  line-height: 1 !important;
  border-radius: 0.25rem; }
  .page-link:focus,
  .page-link > a:focus {
    color: #4E5155; }

.page-link.btn-primary {
  box-shadow: none !important; }

.pagination > li > a:not(.page-link) {
  position: relative;
  display: block;
  padding: 0.625rem 0.3125rem;
  min-width: calc(2.144rem + 2px);
  border: 1px solid rgba(24, 28, 33, 0.06);
  background-color: transparent;
  color: #a3a4a6;
  text-align: center;
  line-height: 1 !important;
  border-radius: 0.25rem; }
  .pagination > li > a:not(.page-link):hover, .pagination > li > a:not(.page-link):focus {
    border-color: rgba(24, 28, 33, 0.1);
    background-color: transparent;
    color: #4E5155;
    text-decoration: none; }
  .pagination > li > a:not(.page-link):focus {
    color: #4E5155; }

.pagination-lg .page-link,
.pagination-lg > li > a:not(.page-link) {
  min-width: calc(2.875rem + 2px);
  border-radius: 0.25rem; }

.pagination-sm .page-link,
.pagination-sm > li > a:not(.page-link) {
  min-width: calc(1.5rem + 2px);
  border-radius: 0.25rem; }

.alert-secondary {
  border-color: #dfe1e3;
  background-color: #edeff2;
  color: #5f6061; }
  .alert-secondary hr {
    border-top-color: #dfe1e3; }
  .alert-secondary .alert-link {
    color: #5f6061; }

.alert-dark-secondary {
  background-color: #8897AA;
  color: #fff; }
  .alert-dark-secondary hr {
    border-top-color: rgba(255, 255, 255, 0.2); }
  .alert-dark-secondary .close,
  .alert-dark-secondary .alert-link {
    color: #fff; }

.alert-success {
  border-color: #cce6dd;
  background-color: #d9f5eb;
  color: #57625e; }
  .alert-success hr {
    border-top-color: #cce6dd; }
  .alert-success .alert-link {
    color: #57625e; }

.alert-dark-success {
  background-color: #02BC77;
  color: #fff; }
  .alert-dark-success hr {
    border-top-color: rgba(255, 255, 255, 0.2); }
  .alert-dark-success .close,
  .alert-dark-success .alert-link {
    color: #fff; }

.alert-info {
  border-color: #d2e7ea;
  background-color: #dff6f9;
  color: #596264; }
  .alert-info hr {
    border-top-color: #d2e7ea; }
  .alert-info .alert-link {
    color: #596264; }

.alert-dark-info {
  background-color: #28c3d7;
  color: #fff; }
  .alert-dark-info hr {
    border-top-color: rgba(255, 255, 255, 0.2); }
  .alert-dark-info .close,
  .alert-dark-info .alert-link {
    color: #fff; }

.alert-warning {
  border-color: #f0ead7;
  background-color: #fff9e5;
  color: #66645c; }
  .alert-warning hr {
    border-top-color: #f0ead7; }
  .alert-warning .alert-link {
    color: #66645c; }

.alert-dark-warning {
  background-color: #FFD950;
  color: #665720; }
  .alert-dark-warning hr {
    border-top-color: rgba(102, 87, 32, 0.2); }
  .alert-dark-warning .close,
  .alert-dark-warning .alert-link {
    color: #665720; }

.alert-danger {
  border-color: #ead7d7;
  background-color: #f9e5e5;
  color: #645c5c; }
  .alert-danger hr {
    border-top-color: #ead7d7; }
  .alert-danger .alert-link {
    color: #645c5c; }

.alert-dark-danger {
  background-color: #d9534f;
  color: #fff; }
  .alert-dark-danger hr {
    border-top-color: rgba(255, 255, 255, 0.2); }
  .alert-dark-danger .close,
  .alert-dark-danger .alert-link {
    color: #fff; }

.alert-dark {
  border-color: #cfd0d1;
  background-color: #dcddde;
  color: #585859; }
  .alert-dark hr {
    border-top-color: #cfd0d1; }
  .alert-dark .alert-link {
    color: #585859; }

.alert-dark-dark {
  background-color: rgba(24, 28, 33, 0.9);
  color: #fff; }
  .alert-dark-dark hr {
    border-top-color: rgba(255, 255, 255, 0.2); }
  .alert-dark-dark .close,
  .alert-dark-dark .alert-link {
    color: #fff; }

.tooltip-inner {
  box-shadow: none; }

.modal-open .tooltip {
  z-index: 1092; }

.tooltip-secondary .tooltip-inner, .tooltip-secondary > .tooltip .tooltip-inner, .ngb-tooltip-secondary + ngb-tooltip-window .tooltip-inner {
  background: #8897AA;
  color: #fff; }

.tooltip-secondary.bs-tooltip-top .arrow::before, .tooltip-secondary.bs-tooltip-auto[x-placement^="top"] .arrow::before, .tooltip-secondary > .tooltip.bs-tooltip-top .arrow::before, .tooltip-secondary > .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-top .arrow::before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #8897AA; }

.tooltip-secondary.bs-tooltip-right .arrow::before, .tooltip-secondary.bs-tooltip-auto[x-placement^="right"] .arrow::before, .tooltip-secondary > .tooltip.bs-tooltip-right .arrow::before, .tooltip-secondary > .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-right .arrow::before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #8897AA; }

.tooltip-secondary.bs-tooltip-bottom .arrow::before, .tooltip-secondary.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .tooltip-secondary > .tooltip.bs-tooltip-bottom .arrow::before, .tooltip-secondary > .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-bottom .arrow::before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #8897AA; }

.tooltip-secondary.bs-tooltip-left .arrow::before, .tooltip-secondary.bs-tooltip-auto[x-placement^="left"] .arrow::before, .tooltip-secondary > .tooltip.bs-tooltip-left .arrow::before, .tooltip-secondary > .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-left .arrow::before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #8897AA; }

.tooltip-success .tooltip-inner, .tooltip-success > .tooltip .tooltip-inner, .ngb-tooltip-success + ngb-tooltip-window .tooltip-inner {
  background: #02BC77;
  color: #fff; }

.tooltip-success.bs-tooltip-top .arrow::before, .tooltip-success.bs-tooltip-auto[x-placement^="top"] .arrow::before, .tooltip-success > .tooltip.bs-tooltip-top .arrow::before, .tooltip-success > .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-top .arrow::before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #02BC77; }

.tooltip-success.bs-tooltip-right .arrow::before, .tooltip-success.bs-tooltip-auto[x-placement^="right"] .arrow::before, .tooltip-success > .tooltip.bs-tooltip-right .arrow::before, .tooltip-success > .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-right .arrow::before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #02BC77; }

.tooltip-success.bs-tooltip-bottom .arrow::before, .tooltip-success.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .tooltip-success > .tooltip.bs-tooltip-bottom .arrow::before, .tooltip-success > .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-bottom .arrow::before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #02BC77; }

.tooltip-success.bs-tooltip-left .arrow::before, .tooltip-success.bs-tooltip-auto[x-placement^="left"] .arrow::before, .tooltip-success > .tooltip.bs-tooltip-left .arrow::before, .tooltip-success > .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-left .arrow::before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #02BC77; }

.tooltip-info .tooltip-inner, .tooltip-info > .tooltip .tooltip-inner, .ngb-tooltip-info + ngb-tooltip-window .tooltip-inner {
  background: #28c3d7;
  color: #fff; }

.tooltip-info.bs-tooltip-top .arrow::before, .tooltip-info.bs-tooltip-auto[x-placement^="top"] .arrow::before, .tooltip-info > .tooltip.bs-tooltip-top .arrow::before, .tooltip-info > .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-top .arrow::before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #28c3d7; }

.tooltip-info.bs-tooltip-right .arrow::before, .tooltip-info.bs-tooltip-auto[x-placement^="right"] .arrow::before, .tooltip-info > .tooltip.bs-tooltip-right .arrow::before, .tooltip-info > .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-right .arrow::before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #28c3d7; }

.tooltip-info.bs-tooltip-bottom .arrow::before, .tooltip-info.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .tooltip-info > .tooltip.bs-tooltip-bottom .arrow::before, .tooltip-info > .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-bottom .arrow::before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #28c3d7; }

.tooltip-info.bs-tooltip-left .arrow::before, .tooltip-info.bs-tooltip-auto[x-placement^="left"] .arrow::before, .tooltip-info > .tooltip.bs-tooltip-left .arrow::before, .tooltip-info > .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-left .arrow::before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #28c3d7; }

.tooltip-warning .tooltip-inner, .tooltip-warning > .tooltip .tooltip-inner, .ngb-tooltip-warning + ngb-tooltip-window .tooltip-inner {
  background: #FFD950;
  color: #665720; }

.tooltip-warning.bs-tooltip-top .arrow::before, .tooltip-warning.bs-tooltip-auto[x-placement^="top"] .arrow::before, .tooltip-warning > .tooltip.bs-tooltip-top .arrow::before, .tooltip-warning > .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-top .arrow::before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #FFD950; }

.tooltip-warning.bs-tooltip-right .arrow::before, .tooltip-warning.bs-tooltip-auto[x-placement^="right"] .arrow::before, .tooltip-warning > .tooltip.bs-tooltip-right .arrow::before, .tooltip-warning > .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-right .arrow::before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #FFD950; }

.tooltip-warning.bs-tooltip-bottom .arrow::before, .tooltip-warning.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .tooltip-warning > .tooltip.bs-tooltip-bottom .arrow::before, .tooltip-warning > .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-bottom .arrow::before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #FFD950; }

.tooltip-warning.bs-tooltip-left .arrow::before, .tooltip-warning.bs-tooltip-auto[x-placement^="left"] .arrow::before, .tooltip-warning > .tooltip.bs-tooltip-left .arrow::before, .tooltip-warning > .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-left .arrow::before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #FFD950; }

.tooltip-danger .tooltip-inner, .tooltip-danger > .tooltip .tooltip-inner, .ngb-tooltip-danger + ngb-tooltip-window .tooltip-inner {
  background: #d9534f;
  color: #fff; }

.tooltip-danger.bs-tooltip-top .arrow::before, .tooltip-danger.bs-tooltip-auto[x-placement^="top"] .arrow::before, .tooltip-danger > .tooltip.bs-tooltip-top .arrow::before, .tooltip-danger > .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-top .arrow::before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #d9534f; }

.tooltip-danger.bs-tooltip-right .arrow::before, .tooltip-danger.bs-tooltip-auto[x-placement^="right"] .arrow::before, .tooltip-danger > .tooltip.bs-tooltip-right .arrow::before, .tooltip-danger > .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-right .arrow::before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #d9534f; }

.tooltip-danger.bs-tooltip-bottom .arrow::before, .tooltip-danger.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .tooltip-danger > .tooltip.bs-tooltip-bottom .arrow::before, .tooltip-danger > .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-bottom .arrow::before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #d9534f; }

.tooltip-danger.bs-tooltip-left .arrow::before, .tooltip-danger.bs-tooltip-auto[x-placement^="left"] .arrow::before, .tooltip-danger > .tooltip.bs-tooltip-left .arrow::before, .tooltip-danger > .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-left .arrow::before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #d9534f; }

.tooltip-dark .tooltip-inner, .tooltip-dark > .tooltip .tooltip-inner, .ngb-tooltip-dark + ngb-tooltip-window .tooltip-inner {
  background: #2f3337;
  color: #fff; }

.tooltip-dark.bs-tooltip-top .arrow::before, .tooltip-dark.bs-tooltip-auto[x-placement^="top"] .arrow::before, .tooltip-dark > .tooltip.bs-tooltip-top .arrow::before, .tooltip-dark > .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-top .arrow::before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #2f3337; }

.tooltip-dark.bs-tooltip-right .arrow::before, .tooltip-dark.bs-tooltip-auto[x-placement^="right"] .arrow::before, .tooltip-dark > .tooltip.bs-tooltip-right .arrow::before, .tooltip-dark > .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-right .arrow::before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #2f3337; }

.tooltip-dark.bs-tooltip-bottom .arrow::before, .tooltip-dark.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .tooltip-dark > .tooltip.bs-tooltip-bottom .arrow::before, .tooltip-dark > .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-bottom .arrow::before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #2f3337; }

.tooltip-dark.bs-tooltip-left .arrow::before, .tooltip-dark.bs-tooltip-auto[x-placement^="left"] .arrow::before, .tooltip-dark > .tooltip.bs-tooltip-left .arrow::before, .tooltip-dark > .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-left .arrow::before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #2f3337; }

.popover {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.09); }
  .popover .arrow {
    z-index: 1; }

.modal-open .popover {
  z-index: 1091; }

.popover-secondary, .popover-secondary > .popover, .ngb-popover-secondary + ngb-popover-window {
  border-color: transparent;
  background: #8897AA; }
  .popover-secondary .popover-header, .popover-secondary > .popover .popover-header, .ngb-popover-secondary + ngb-popover-window .popover-header {
    border-color: rgba(255, 255, 255, 0.2);
    background: transparent;
    color: #fff; }
  .popover-secondary .popover-body, .popover-secondary > .popover .popover-body, .ngb-popover-secondary + ngb-popover-window .popover-body {
    background: transparent;
    color: #fff; }
  .popover-secondary .arrow::before, .popover-secondary > .popover .arrow::before, .ngb-popover-secondary + ngb-popover-window .arrow::before {
    border-color: transparent !important; }
  .popover-secondary.bs-popover-top .arrow::after, .popover-secondary.bs-popover-auto[x-placement^="top"] .arrow::after, .popover-secondary > .popover.bs-popover-top .arrow::after, .popover-secondary > .popover.bs-popover-auto[x-placement^="top"] .arrow::after, .ngb-popover-secondary + ngb-popover-window.bs-popover-top .arrow::after, .ngb-popover-secondary + ngb-popover-window.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #8897AA; }
  .popover-secondary.bs-popover-right .arrow::after, .popover-secondary.bs-popover-auto[x-placement^="right"] .arrow::after, .popover-secondary > .popover.bs-popover-right .arrow::after, .popover-secondary > .popover.bs-popover-auto[x-placement^="right"] .arrow::after, .ngb-popover-secondary + ngb-popover-window.bs-popover-right .arrow::after, .ngb-popover-secondary + ngb-popover-window.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #8897AA; }
  .popover-secondary.bs-popover-bottom .arrow::after, .popover-secondary.bs-popover-auto[x-placement^="bottom"] .arrow::after, .popover-secondary > .popover.bs-popover-bottom .arrow::after, .popover-secondary > .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after, .ngb-popover-secondary + ngb-popover-window.bs-popover-bottom .arrow::after, .ngb-popover-secondary + ngb-popover-window.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #8897AA; }
  .popover-secondary.bs-popover-left .arrow::after, .popover-secondary.bs-popover-auto[x-placement^="left"] .arrow::after, .popover-secondary > .popover.bs-popover-left .arrow::after, .popover-secondary > .popover.bs-popover-auto[x-placement^="left"] .arrow::after, .ngb-popover-secondary + ngb-popover-window.bs-popover-left .arrow::after, .ngb-popover-secondary + ngb-popover-window.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #8897AA; }

.popover-success, .popover-success > .popover, .ngb-popover-success + ngb-popover-window {
  border-color: transparent;
  background: #02BC77; }
  .popover-success .popover-header, .popover-success > .popover .popover-header, .ngb-popover-success + ngb-popover-window .popover-header {
    border-color: rgba(255, 255, 255, 0.2);
    background: transparent;
    color: #fff; }
  .popover-success .popover-body, .popover-success > .popover .popover-body, .ngb-popover-success + ngb-popover-window .popover-body {
    background: transparent;
    color: #fff; }
  .popover-success .arrow::before, .popover-success > .popover .arrow::before, .ngb-popover-success + ngb-popover-window .arrow::before {
    border-color: transparent !important; }
  .popover-success.bs-popover-top .arrow::after, .popover-success.bs-popover-auto[x-placement^="top"] .arrow::after, .popover-success > .popover.bs-popover-top .arrow::after, .popover-success > .popover.bs-popover-auto[x-placement^="top"] .arrow::after, .ngb-popover-success + ngb-popover-window.bs-popover-top .arrow::after, .ngb-popover-success + ngb-popover-window.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #02BC77; }
  .popover-success.bs-popover-right .arrow::after, .popover-success.bs-popover-auto[x-placement^="right"] .arrow::after, .popover-success > .popover.bs-popover-right .arrow::after, .popover-success > .popover.bs-popover-auto[x-placement^="right"] .arrow::after, .ngb-popover-success + ngb-popover-window.bs-popover-right .arrow::after, .ngb-popover-success + ngb-popover-window.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #02BC77; }
  .popover-success.bs-popover-bottom .arrow::after, .popover-success.bs-popover-auto[x-placement^="bottom"] .arrow::after, .popover-success > .popover.bs-popover-bottom .arrow::after, .popover-success > .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after, .ngb-popover-success + ngb-popover-window.bs-popover-bottom .arrow::after, .ngb-popover-success + ngb-popover-window.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #02BC77; }
  .popover-success.bs-popover-left .arrow::after, .popover-success.bs-popover-auto[x-placement^="left"] .arrow::after, .popover-success > .popover.bs-popover-left .arrow::after, .popover-success > .popover.bs-popover-auto[x-placement^="left"] .arrow::after, .ngb-popover-success + ngb-popover-window.bs-popover-left .arrow::after, .ngb-popover-success + ngb-popover-window.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #02BC77; }

.popover-info, .popover-info > .popover, .ngb-popover-info + ngb-popover-window {
  border-color: transparent;
  background: #28c3d7; }
  .popover-info .popover-header, .popover-info > .popover .popover-header, .ngb-popover-info + ngb-popover-window .popover-header {
    border-color: rgba(255, 255, 255, 0.2);
    background: transparent;
    color: #fff; }
  .popover-info .popover-body, .popover-info > .popover .popover-body, .ngb-popover-info + ngb-popover-window .popover-body {
    background: transparent;
    color: #fff; }
  .popover-info .arrow::before, .popover-info > .popover .arrow::before, .ngb-popover-info + ngb-popover-window .arrow::before {
    border-color: transparent !important; }
  .popover-info.bs-popover-top .arrow::after, .popover-info.bs-popover-auto[x-placement^="top"] .arrow::after, .popover-info > .popover.bs-popover-top .arrow::after, .popover-info > .popover.bs-popover-auto[x-placement^="top"] .arrow::after, .ngb-popover-info + ngb-popover-window.bs-popover-top .arrow::after, .ngb-popover-info + ngb-popover-window.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #28c3d7; }
  .popover-info.bs-popover-right .arrow::after, .popover-info.bs-popover-auto[x-placement^="right"] .arrow::after, .popover-info > .popover.bs-popover-right .arrow::after, .popover-info > .popover.bs-popover-auto[x-placement^="right"] .arrow::after, .ngb-popover-info + ngb-popover-window.bs-popover-right .arrow::after, .ngb-popover-info + ngb-popover-window.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #28c3d7; }
  .popover-info.bs-popover-bottom .arrow::after, .popover-info.bs-popover-auto[x-placement^="bottom"] .arrow::after, .popover-info > .popover.bs-popover-bottom .arrow::after, .popover-info > .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after, .ngb-popover-info + ngb-popover-window.bs-popover-bottom .arrow::after, .ngb-popover-info + ngb-popover-window.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #28c3d7; }
  .popover-info.bs-popover-left .arrow::after, .popover-info.bs-popover-auto[x-placement^="left"] .arrow::after, .popover-info > .popover.bs-popover-left .arrow::after, .popover-info > .popover.bs-popover-auto[x-placement^="left"] .arrow::after, .ngb-popover-info + ngb-popover-window.bs-popover-left .arrow::after, .ngb-popover-info + ngb-popover-window.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #28c3d7; }

.popover-warning, .popover-warning > .popover, .ngb-popover-warning + ngb-popover-window {
  border-color: transparent;
  background: #FFD950; }
  .popover-warning .popover-header, .popover-warning > .popover .popover-header, .ngb-popover-warning + ngb-popover-window .popover-header {
    border-color: rgba(102, 87, 32, 0.2);
    background: transparent;
    color: #665720; }
  .popover-warning .popover-body, .popover-warning > .popover .popover-body, .ngb-popover-warning + ngb-popover-window .popover-body {
    background: transparent;
    color: #665720; }
  .popover-warning .arrow::before, .popover-warning > .popover .arrow::before, .ngb-popover-warning + ngb-popover-window .arrow::before {
    border-color: transparent !important; }
  .popover-warning.bs-popover-top .arrow::after, .popover-warning.bs-popover-auto[x-placement^="top"] .arrow::after, .popover-warning > .popover.bs-popover-top .arrow::after, .popover-warning > .popover.bs-popover-auto[x-placement^="top"] .arrow::after, .ngb-popover-warning + ngb-popover-window.bs-popover-top .arrow::after, .ngb-popover-warning + ngb-popover-window.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #FFD950; }
  .popover-warning.bs-popover-right .arrow::after, .popover-warning.bs-popover-auto[x-placement^="right"] .arrow::after, .popover-warning > .popover.bs-popover-right .arrow::after, .popover-warning > .popover.bs-popover-auto[x-placement^="right"] .arrow::after, .ngb-popover-warning + ngb-popover-window.bs-popover-right .arrow::after, .ngb-popover-warning + ngb-popover-window.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #FFD950; }
  .popover-warning.bs-popover-bottom .arrow::after, .popover-warning.bs-popover-auto[x-placement^="bottom"] .arrow::after, .popover-warning > .popover.bs-popover-bottom .arrow::after, .popover-warning > .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after, .ngb-popover-warning + ngb-popover-window.bs-popover-bottom .arrow::after, .ngb-popover-warning + ngb-popover-window.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #FFD950; }
  .popover-warning.bs-popover-left .arrow::after, .popover-warning.bs-popover-auto[x-placement^="left"] .arrow::after, .popover-warning > .popover.bs-popover-left .arrow::after, .popover-warning > .popover.bs-popover-auto[x-placement^="left"] .arrow::after, .ngb-popover-warning + ngb-popover-window.bs-popover-left .arrow::after, .ngb-popover-warning + ngb-popover-window.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #FFD950; }

.popover-danger, .popover-danger > .popover, .ngb-popover-danger + ngb-popover-window {
  border-color: transparent;
  background: #d9534f; }
  .popover-danger .popover-header, .popover-danger > .popover .popover-header, .ngb-popover-danger + ngb-popover-window .popover-header {
    border-color: rgba(255, 255, 255, 0.2);
    background: transparent;
    color: #fff; }
  .popover-danger .popover-body, .popover-danger > .popover .popover-body, .ngb-popover-danger + ngb-popover-window .popover-body {
    background: transparent;
    color: #fff; }
  .popover-danger .arrow::before, .popover-danger > .popover .arrow::before, .ngb-popover-danger + ngb-popover-window .arrow::before {
    border-color: transparent !important; }
  .popover-danger.bs-popover-top .arrow::after, .popover-danger.bs-popover-auto[x-placement^="top"] .arrow::after, .popover-danger > .popover.bs-popover-top .arrow::after, .popover-danger > .popover.bs-popover-auto[x-placement^="top"] .arrow::after, .ngb-popover-danger + ngb-popover-window.bs-popover-top .arrow::after, .ngb-popover-danger + ngb-popover-window.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #d9534f; }
  .popover-danger.bs-popover-right .arrow::after, .popover-danger.bs-popover-auto[x-placement^="right"] .arrow::after, .popover-danger > .popover.bs-popover-right .arrow::after, .popover-danger > .popover.bs-popover-auto[x-placement^="right"] .arrow::after, .ngb-popover-danger + ngb-popover-window.bs-popover-right .arrow::after, .ngb-popover-danger + ngb-popover-window.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #d9534f; }
  .popover-danger.bs-popover-bottom .arrow::after, .popover-danger.bs-popover-auto[x-placement^="bottom"] .arrow::after, .popover-danger > .popover.bs-popover-bottom .arrow::after, .popover-danger > .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after, .ngb-popover-danger + ngb-popover-window.bs-popover-bottom .arrow::after, .ngb-popover-danger + ngb-popover-window.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #d9534f; }
  .popover-danger.bs-popover-left .arrow::after, .popover-danger.bs-popover-auto[x-placement^="left"] .arrow::after, .popover-danger > .popover.bs-popover-left .arrow::after, .popover-danger > .popover.bs-popover-auto[x-placement^="left"] .arrow::after, .ngb-popover-danger + ngb-popover-window.bs-popover-left .arrow::after, .ngb-popover-danger + ngb-popover-window.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #d9534f; }

.popover-dark, .popover-dark > .popover, .ngb-popover-dark + ngb-popover-window {
  border-color: transparent;
  background: #2f3337; }
  .popover-dark .popover-header, .popover-dark > .popover .popover-header, .ngb-popover-dark + ngb-popover-window .popover-header {
    border-color: rgba(255, 255, 255, 0.2);
    background: transparent;
    color: #fff; }
  .popover-dark .popover-body, .popover-dark > .popover .popover-body, .ngb-popover-dark + ngb-popover-window .popover-body {
    background: transparent;
    color: #fff; }
  .popover-dark .arrow::before, .popover-dark > .popover .arrow::before, .ngb-popover-dark + ngb-popover-window .arrow::before {
    border-color: transparent !important; }
  .popover-dark.bs-popover-top .arrow::after, .popover-dark.bs-popover-auto[x-placement^="top"] .arrow::after, .popover-dark > .popover.bs-popover-top .arrow::after, .popover-dark > .popover.bs-popover-auto[x-placement^="top"] .arrow::after, .ngb-popover-dark + ngb-popover-window.bs-popover-top .arrow::after, .ngb-popover-dark + ngb-popover-window.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #2f3337; }
  .popover-dark.bs-popover-right .arrow::after, .popover-dark.bs-popover-auto[x-placement^="right"] .arrow::after, .popover-dark > .popover.bs-popover-right .arrow::after, .popover-dark > .popover.bs-popover-auto[x-placement^="right"] .arrow::after, .ngb-popover-dark + ngb-popover-window.bs-popover-right .arrow::after, .ngb-popover-dark + ngb-popover-window.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #2f3337; }
  .popover-dark.bs-popover-bottom .arrow::after, .popover-dark.bs-popover-auto[x-placement^="bottom"] .arrow::after, .popover-dark > .popover.bs-popover-bottom .arrow::after, .popover-dark > .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after, .ngb-popover-dark + ngb-popover-window.bs-popover-bottom .arrow::after, .ngb-popover-dark + ngb-popover-window.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #2f3337; }
  .popover-dark.bs-popover-left .arrow::after, .popover-dark.bs-popover-auto[x-placement^="left"] .arrow::after, .popover-dark > .popover.bs-popover-left .arrow::after, .popover-dark > .popover.bs-popover-auto[x-placement^="left"] .arrow::after, .ngb-popover-dark + ngb-popover-window.bs-popover-left .arrow::after, .ngb-popover-dark + ngb-popover-window.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #2f3337; }

.form-control {
  min-height: calc(2.25rem + 2px); }

.form-control-sm,
.input-group-sm .form-control {
  min-height: calc(1.5rem + 2px); }

.form-control-lg,
.input-group-lg .form-control {
  min-height: calc(3rem + 2px); }

select.form-control[size="0"]:not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control-sm[size="0"]:not([multiple]) {
  height: calc(1.5rem + 2px); }

select.form-control-lg[size="0"]:not([multiple]) {
  height: calc(3rem + 2px); }

.form-control-plaintext {
  color: #4E5155; }
  .form-inline .form-control-plaintext {
    width: auto; }

.form-control-inverted {
  border-color: rgba(255, 255, 255, 0.3); }
  .form-control-inverted, .form-control-inverted:hover, .form-control-inverted:focus {
    background-color: rgba(255, 255, 255, 0.3);
    color: #fff; }
  .form-control-inverted::placeholder {
    color: rgba(255, 255, 255, 0.7); }
  .form-control-inverted:disabled {
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: #fff !important;
    border-color: rgba(255, 255, 255, 0.1) !important; }
  .form-control-inverted option {
    color: #4E5155 !important; }

.form-label,
.col-form-label,
.col-form-legend {
  margin-bottom: calc(0.438rem - 2px);
  font-weight: 500;
  font-size: 0.83125rem; }

.col-form-label,
.col-form-legend {
  padding-bottom: 0;
  line-height: 1.54; }

.form-label-sm,
.col-form-label-sm,
.col-form-legend-sm {
  font-size: 0.75rem !important;
  line-height: 1.5; }

.form-label-lg,
.col-form-label-lg,
.col-form-legend-lg {
  font-size: 1rem !important;
  line-height: 1.5; }

.form-check {
  min-height: 1.31418rem; }

.valid-feedback {
  display: none;
  margin-top: 0.25rem;
  color: #02BC77; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  margin-top: .1rem;
  padding: .5rem;
  width: 250px;
  border-radius: .2rem;
  background-color: rgba(2, 188, 119, 0.8);
  color: #fff;
  line-height: 1; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #02BC77 !important; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #02BC77 !important;
    box-shadow: 0 0 0 2px rgba(2, 188, 119, 0.25) !important; }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip,
.form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control.custom-checkbox .custom-control-input:valid ~ .custom-control-label, .custom-control.custom-checkbox .custom-control-input.is-valid ~ .custom-control-label, .was-validated
.custom-control.custom-radio .custom-control-input:valid ~ .custom-control-label,
.custom-control.custom-radio .custom-control-input.is-valid ~ .custom-control-label {
  color: #02BC77; }

.was-validated .custom-control.custom-checkbox .custom-control-input:valid ~ .custom-control-label::before,
.was-validated .custom-control.custom-checkbox .custom-control-input:valid:focus ~ .custom-control-label::before,
.was-validated .custom-control.custom-checkbox .custom-control-input:valid:active ~ .custom-control-label::before, .custom-control.custom-checkbox .custom-control-input.is-valid ~ .custom-control-label::before,
.custom-control.custom-checkbox .custom-control-input.is-valid:focus ~ .custom-control-label::before,
.custom-control.custom-checkbox .custom-control-input.is-valid:active ~ .custom-control-label::before, .was-validated
.custom-control.custom-radio .custom-control-input:valid ~ .custom-control-label::before,
.was-validated
.custom-control.custom-radio .custom-control-input:valid:focus ~ .custom-control-label::before,
.was-validated
.custom-control.custom-radio .custom-control-input:valid:active ~ .custom-control-label::before,
.custom-control.custom-radio .custom-control-input.is-valid ~ .custom-control-label::before,
.custom-control.custom-radio .custom-control-input.is-valid:focus ~ .custom-control-label::before,
.custom-control.custom-radio .custom-control-input.is-valid:active ~ .custom-control-label::before {
  border-color: #02BC77; }

.was-validated .custom-control.custom-checkbox .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control.custom-checkbox .custom-control-input.is-valid:focus ~ .custom-control-label::before, .was-validated
.custom-control.custom-radio .custom-control-input:valid:focus ~ .custom-control-label::before,
.custom-control.custom-radio .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 2px rgba(2, 188, 119, 0.4); }

.was-validated .custom-control.custom-checkbox .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control.custom-checkbox .custom-control-input.is-valid:checked ~ .custom-control-label::before, .was-validated
.custom-control.custom-radio .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control.custom-radio .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #02BC77;
  background-color: #02BC77; }

.was-validated .custom-control.custom-checkbox .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control.custom-checkbox .custom-control-input:valid ~ .valid-tooltip, .custom-control.custom-checkbox .custom-control-input.is-valid ~ .valid-feedback,
.custom-control.custom-checkbox .custom-control-input.is-valid ~ .valid-tooltip, .was-validated
.custom-control.custom-radio .custom-control-input:valid ~ .valid-feedback,
.was-validated
.custom-control.custom-radio .custom-control-input:valid ~ .valid-tooltip,
.custom-control.custom-radio .custom-control-input.is-valid ~ .valid-feedback,
.custom-control.custom-radio .custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control.custom-checkbox .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control.custom-checkbox .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E"); }

.was-validated .custom-control.custom-radio .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control.custom-radio .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #02BC77 !important; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::after, .custom-file-input.is-valid ~ .custom-file-label::after {
    border-color: inherit !important; }

.was-validated .custom-file-input:valid:focus, .custom-file-input.is-valid:focus {
  box-shadow: 0 0 0 2px rgba(2, 188, 119, 0.3) !important; }

.invalid-feedback {
  display: none;
  margin-top: 0.25rem;
  color: #d9534f; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  margin-top: .1rem;
  padding: .5rem;
  width: 250px;
  border-radius: .2rem;
  background-color: rgba(217, 83, 79, 0.8);
  color: #fff;
  line-height: 1; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #d9534f !important; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #d9534f !important;
    box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.25) !important; }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip,
.form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control.custom-checkbox .custom-control-input:invalid ~ .custom-control-label, .custom-control.custom-checkbox .custom-control-input.is-invalid ~ .custom-control-label, .was-validated
.custom-control.custom-radio .custom-control-input:invalid ~ .custom-control-label,
.custom-control.custom-radio .custom-control-input.is-invalid ~ .custom-control-label {
  color: #d9534f; }

.was-validated .custom-control.custom-checkbox .custom-control-input:invalid ~ .custom-control-label::before,
.was-validated .custom-control.custom-checkbox .custom-control-input:invalid:focus ~ .custom-control-label::before,
.was-validated .custom-control.custom-checkbox .custom-control-input:invalid:active ~ .custom-control-label::before, .custom-control.custom-checkbox .custom-control-input.is-invalid ~ .custom-control-label::before,
.custom-control.custom-checkbox .custom-control-input.is-invalid:focus ~ .custom-control-label::before,
.custom-control.custom-checkbox .custom-control-input.is-invalid:active ~ .custom-control-label::before, .was-validated
.custom-control.custom-radio .custom-control-input:invalid ~ .custom-control-label::before,
.was-validated
.custom-control.custom-radio .custom-control-input:invalid:focus ~ .custom-control-label::before,
.was-validated
.custom-control.custom-radio .custom-control-input:invalid:active ~ .custom-control-label::before,
.custom-control.custom-radio .custom-control-input.is-invalid ~ .custom-control-label::before,
.custom-control.custom-radio .custom-control-input.is-invalid:focus ~ .custom-control-label::before,
.custom-control.custom-radio .custom-control-input.is-invalid:active ~ .custom-control-label::before {
  border-color: #d9534f; }

.was-validated .custom-control.custom-checkbox .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control.custom-checkbox .custom-control-input.is-invalid:focus ~ .custom-control-label::before, .was-validated
.custom-control.custom-radio .custom-control-input:invalid:focus ~ .custom-control-label::before,
.custom-control.custom-radio .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.4); }

.was-validated .custom-control.custom-checkbox .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control.custom-checkbox .custom-control-input.is-invalid:checked ~ .custom-control-label::before, .was-validated
.custom-control.custom-radio .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control.custom-radio .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #d9534f;
  background-color: #d9534f; }

.was-validated .custom-control.custom-checkbox .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control.custom-checkbox .custom-control-input:invalid ~ .invalid-tooltip, .custom-control.custom-checkbox .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control.custom-checkbox .custom-control-input.is-invalid ~ .invalid-tooltip, .was-validated
.custom-control.custom-radio .custom-control-input:invalid ~ .invalid-feedback,
.was-validated
.custom-control.custom-radio .custom-control-input:invalid ~ .invalid-tooltip,
.custom-control.custom-radio .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control.custom-radio .custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control.custom-checkbox .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control.custom-checkbox .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E"); }

.was-validated .custom-control.custom-radio .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control.custom-radio .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #d9534f !important; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
    border-color: inherit !important; }

.was-validated .custom-file-input:invalid:focus, .custom-file-input.is-invalid:focus {
  box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.3) !important; }

.form-inline .custom-control {
  display: block;
  width: 100%; }

@media (min-width: 576px) {
  .form-inline .custom-control,
  .form-inline .custom-select,
  .form-inline .form-control-plaintext {
    display: inline-block;
    width: auto; }
  .form-inline .form-text {
    margin-top: 0; }
  .form-inline .form-label {
    margin-bottom: 0 !important; } }

.custom-control {
  position: relative;
  min-height: 1.125rem; }

.custom-control-label {
  position: static; }
  .custom-control-label::before {
    top: 0.0625rem;
    left: 0;
    border: 1px solid rgba(24, 28, 33, 0.1);
    background-color: #fff;
    background-position: center center;
    background-repeat: no-repeat;
    transition: all .2s;
    pointer-events: auto; }
  .custom-control-label::after {
    display: none; }

.custom-checkbox .custom-control-label::before {
  background-size: 65%; }

.custom-checkbox .custom-control-input:indeterminate:not(:checked) ~ .custom-control-label::before {
  background-size: 0.5rem; }

.custom-radio .custom-control-label::before {
  background-size: 0.5rem; }

.custom-control-input:active ~ .custom-control-label::before {
  box-shadow: none; }

.custom-control-input:active:not(:checked) ~ .custom-control-label::before {
  background-color: #fff; }

.custom-control-input:disabled ~ .custom-control-label::before,
fieldset[disabled] .custom-control-input ~ .custom-control-label::before {
  border-color: rgba(24, 28, 33, 0.1) !important;
  background-color: #f1f1f2 !important; }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before,
fieldset[disabled] .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23a3a4a6' d='M0 2h4'/%3E%3C/svg%3E") !important; }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before,
fieldset[disabled] .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23a3a4a6;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E") !important; }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before,
fieldset[disabled] .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23a3a4a6'/%3E%3C/svg%3E") !important; }

.custom-controls-stacked .custom-control {
  display: block;
  margin-bottom: .5rem; }

.custom-select {
  display: block;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAYCAYAAACfpi8JAAAABGdBTUEAALGPC/xhBQAAAKBJREFUSA3t1kEKgCAQBVCTDtciulJ2pWjRiapTaPwokUEjRW0zgaHSOI9PQWLbD4Mhfr7kz/1te4bYKO4JJ8KJ0ATouqUbqeuuH5TRekypb6Scsr2s6zIrHBgLQQ1qs0EAiMU8CNRmhcRgXEQRyBcMRRSDvGF8iKIQHyaEwLOixm8APm2Mq2HoVgMS6u3uZ/9q3MNj5gyhaXEinAhNgK5PpzhEO/hSMK4AAAAASUVORK5CYII=") !important;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .custom-select[size="0"]:not([multiple]) {
    padding-right: 2.75rem; }

.custom-select-inverted {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAYCAYAAACfpi8JAAAABGdBTUEAALGPC/xhBQAAAJ5JREFUSA3t1G0KgCAMBuCMDtZlojOFhzVfUaFlH44N/0yIspw+vGhTCGHFNQ1u8+D16/IGqVHkB0vEEqEJ0P5CX3D78V+0x9qNWe/FNqtz7ogIz4B41IpBAGBgEgK1opBOTEWoQH5iLgg1yAfmhlCFPGCaCIwVO76YrNWwgePRTp/yZm4N04dg1TdAUYmfmjJx790gNDFLxBKhCdD+CQ3GHngvJTGKAAAAAElFTkSuQmCC") !important; }
  .custom-select-inverted, .custom-select-inverted:focus {
    background-color: rgba(255, 255, 255, 0.3);
    color: #fff;
    border-color: transparent; }
  .custom-select-inverted:disabled {
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: #fff !important; }
  .custom-select-inverted::placeholder {
    color: rgba(255, 255, 255, 0.7); }
  .custom-select-inverted option {
    color: #4E5155 !important; }

.custom-select-sm,
.input-group-sm .custom-select {
  padding: 0.188rem 2.5rem 0.188rem 0.625rem;
  height: calc(1.5rem + 2px);
  background-position: right 0.625rem center;
  font-size: 0.75rem;
  line-height: 1.5; }
  .custom-select-sm[size="0"]:not([multiple]),
  .input-group-sm .custom-select[size="0"]:not([multiple]) {
    padding-right: 2.5rem; }

.custom-select-lg,
.input-group-lg .custom-select {
  padding: 0.75rem 3.125rem 0.75rem 1.25rem;
  height: calc(3rem + 2px);
  background-position: right 1.25rem center;
  font-size: 1rem;
  line-height: 1.5; }
  .custom-select-lg[size="0"]:not([multiple]),
  .input-group-lg .custom-select[size="0"]:not([multiple]) {
    padding-right: 3.125rem; }

.custom-select[class][multiple],
.custom-select[size]:not([size="1"]):not([size="0"]) {
  padding-right: 0.875rem !important;
  padding-left: 0.875rem !important;
  height: auto !important;
  background-image: none !important; }

.custom-select-sm[class][multiple], .custom-select-sm[size]:not([size="1"]):not([size="0"]),
.input-group-sm .custom-select[class][multiple],
.input-group-sm .custom-select[size]:not([size="1"]):not([size="0"]) {
  padding-right: 0.625rem !important;
  padding-left: 0.625rem !important; }

.custom-select-lg[class][multiple], .custom-select-lg[size]:not([size="1"]):not([size="0"]),
.input-group-lg .custom-select[class][multiple],
.input-group-lg .custom-select[size]:not([size="1"]):not([size="0"]) {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important; }

.custom-file {
  display: block;
  width: 100%; }

.custom-file-label {
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .custom-file-input:disabled ~ .custom-file-label {
    background: #f1f1f2; }
    .custom-file-input:disabled ~ .custom-file-label::before {
      opacity: .65; }

.switcher {
  position: relative;
  display: inline-block;
  margin-right: 0.75rem;
  margin-bottom: 0;
  border-radius: 60rem;
  vertical-align: middle;
  font-weight: normal;
  padding-left: 2.375rem;
  min-height: 1.375rem;
  font-size: 0.894rem;
  line-height: 1.4; }
  .switcher .switcher-blank .switcher-indicator {
    top: 0 !important; }
  .switcher .switcher-indicator {
    width: 2.375rem;
    height: 1.375rem;
    font-size: 0.625rem;
    line-height: 1.375rem;
    top: 0; }
  .switcher .switcher-label {
    top: 0.0617rem; }
  .switcher .switcher-input:checked ~ .switcher-indicator::after {
    left: 1rem; }
  .switcher .switcher-indicator::after {
    top: 0;
    margin: 0.25rem 0 0 0.25rem;
    width: 0.875rem;
    height: 0.875rem; }
  .switcher .switcher-no {
    padding-right: 0.25rem;
    padding-left: 1.125rem; }
  .switcher .switcher-yes {
    padding-right: 1.125rem;
    padding-left: 0.25rem; }
  @media (min-width: 576px) {
    .form-inline .switcher {
      display: block;
      margin-right: 0; } }

.switcher-input {
  position: absolute;
  z-index: -1;
  margin: 0;
  padding: 0;
  opacity: 0; }

.switcher-indicator {
  position: absolute;
  left: 0;
  overflow: hidden;
  border-radius: 60rem;
  background: rgba(24, 28, 33, 0.1);
  color: rgba(24, 28, 33, 0.3);
  font-weight: bold;
  cursor: default;
  transition-duration: .2s;
  transition-property: left, right, background, box-shadow;
  user-select: none; }

.switcher-label {
  position: relative;
  display: inline-block;
  padding-left: 0.5rem;
  color: #4E5155;
  font-weight: 400;
  cursor: default; }

.switcher-no,
.switcher-yes {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  transition-duration: .2s;
  transition-property: left, right; }

.switcher-no {
  left: 0; }

.switcher-yes {
  left: -100%; }
  .switcher-input:not(:checked) ~ .switcher-indicator .switcher-yes {
    color: transparent !important; }

.switcher-input:checked ~ .switcher-indicator .switcher-no {
  left: 100%;
  color: transparent !important; }

.switcher-input:checked ~ .switcher-indicator .switcher-yes {
  left: 0; }

.switcher-indicator::after {
  content: "";
  position: absolute;
  left: 0;
  display: block;
  border-radius: 999px;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(24, 28, 33, 0.04), 0 1px 6px rgba(24, 28, 33, 0.09);
  transition-duration: .2s;
  transition-property: left, right, background; }

.switcher-input:disabled ~ .switcher-indicator,
fieldset[disabled] .switcher-input ~ .switcher-indicator {
  opacity: .5;
  cursor: not-allowed !important; }
  .switcher-input:disabled ~ .switcher-indicator::after,
  fieldset[disabled] .switcher-input ~ .switcher-indicator::after {
    box-shadow: none !important; }

.switcher-input:disabled ~ .switcher-label,
fieldset[disabled] .switcher-input ~ .switcher-label {
  color: #a3a4a6 !important;
  cursor: not-allowed; }

.switchers-stacked::after {
  display: block;
  clear: both;
  content: ""; }

.switchers-stacked .switcher {
  display: block;
  margin-right: 0;
  margin-bottom: 0.5rem; }

.switcher-square,
.switcher-square .switcher-indicator {
  border-radius: 0.25rem; }

.switcher-square .switcher-indicator::after {
  border-radius: calc(0.25rem - 2px); }

.switcher-sm,
.form-group-sm .switcher {
  padding-left: 2rem;
  min-height: 1.125rem;
  font-size: 0.75rem;
  line-height: 1.486; }
  .switcher-sm .switcher-blank .switcher-indicator,
  .form-group-sm .switcher .switcher-blank .switcher-indicator {
    top: 0 !important; }
  .switcher-sm .switcher-indicator,
  .form-group-sm .switcher .switcher-indicator {
    width: 2rem;
    height: 1.125rem;
    font-size: 0.625rem;
    line-height: 1.125rem;
    top: 0; }
  .switcher-sm .switcher-label,
  .form-group-sm .switcher .switcher-label {
    top: 0.00525rem; }
  .switcher-sm .switcher-input:checked ~ .switcher-indicator::after,
  .form-group-sm .switcher .switcher-input:checked ~ .switcher-indicator::after {
    left: 0.875rem; }
  .switcher-sm .switcher-indicator::after,
  .form-group-sm .switcher .switcher-indicator::after {
    top: 0;
    margin: 0.25rem 0 0 0.25rem;
    width: 0.625rem;
    height: 0.625rem; }
  .switcher-sm .switcher-no,
  .form-group-sm .switcher .switcher-no {
    padding-right: 0.25rem;
    padding-left: 0.875rem; }
  .switcher-sm .switcher-yes,
  .form-group-sm .switcher .switcher-yes {
    padding-right: 0.875rem;
    padding-left: 0.25rem; }

.switcher-lg,
.form-group-lg .switcher {
  padding-left: 3.25rem;
  min-height: 1.75rem;
  font-size: 1rem;
  line-height: 1.47; }
  .switcher-lg .switcher-blank .switcher-indicator,
  .form-group-lg .switcher .switcher-blank .switcher-indicator {
    top: 0 !important; }
  .switcher-lg .switcher-indicator,
  .form-group-lg .switcher .switcher-indicator {
    width: 3.25rem;
    height: 1.75rem;
    font-size: 0.625rem;
    line-height: 1.75rem;
    top: 0; }
  .switcher-lg .switcher-label,
  .form-group-lg .switcher .switcher-label {
    top: 0.14rem; }
  .switcher-lg .switcher-input:checked ~ .switcher-indicator::after,
  .form-group-lg .switcher .switcher-input:checked ~ .switcher-indicator::after {
    left: 1.5rem; }
  .switcher-lg .switcher-indicator::after,
  .form-group-lg .switcher .switcher-indicator::after {
    top: 0;
    margin: 0.25rem 0 0 0.25rem;
    width: 1.25rem;
    height: 1.25rem; }
  .switcher-lg .switcher-no,
  .form-group-lg .switcher .switcher-no {
    padding-right: 0.25rem;
    padding-left: 1.5rem; }
  .switcher-lg .switcher-yes,
  .form-group-lg .switcher .switcher-yes {
    padding-right: 1.5rem;
    padding-left: 0.25rem; }

.switcher-secondary.switcher .switcher-input:checked ~ .switcher-indicator {
  background: #8897AA;
  color: #fff; }

.switcher-secondary.switcher .switcher-input:focus ~ .switcher-indicator {
  box-shadow: 0 0 0 2px rgba(136, 151, 170, 0.4); }

.switcher-secondary.switcher .switcher-input:active ~ .switcher-indicator {
  box-shadow: none !important; }

.switcher-success.switcher .switcher-input:checked ~ .switcher-indicator {
  background: #02BC77;
  color: #fff; }

.switcher-success.switcher .switcher-input:focus ~ .switcher-indicator {
  box-shadow: 0 0 0 2px rgba(2, 188, 119, 0.4); }

.switcher-success.switcher .switcher-input:active ~ .switcher-indicator {
  box-shadow: none !important; }

.switcher-info.switcher .switcher-input:checked ~ .switcher-indicator {
  background: #28c3d7;
  color: #fff; }

.switcher-info.switcher .switcher-input:focus ~ .switcher-indicator {
  box-shadow: 0 0 0 2px rgba(40, 195, 215, 0.4); }

.switcher-info.switcher .switcher-input:active ~ .switcher-indicator {
  box-shadow: none !important; }

.switcher-warning.switcher .switcher-input:checked ~ .switcher-indicator {
  background: #FFD950;
  color: #665720; }

.switcher-warning.switcher .switcher-input:focus ~ .switcher-indicator {
  box-shadow: 0 0 0 2px rgba(255, 217, 80, 0.4); }

.switcher-warning.switcher .switcher-input:active ~ .switcher-indicator {
  box-shadow: none !important; }

.switcher-danger.switcher .switcher-input:checked ~ .switcher-indicator {
  background: #d9534f;
  color: #fff; }

.switcher-danger.switcher .switcher-input:focus ~ .switcher-indicator {
  box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.4); }

.switcher-danger.switcher .switcher-input:active ~ .switcher-indicator {
  box-shadow: none !important; }

.switcher-dark.switcher .switcher-input:checked ~ .switcher-indicator {
  background: rgba(24, 28, 33, 0.9);
  color: #fff; }

.switcher-dark.switcher .switcher-input:focus ~ .switcher-indicator {
  box-shadow: 0 0 0 2px rgba(24, 28, 33, 0.4); }

.switcher-dark.switcher .switcher-input:active ~ .switcher-indicator {
  box-shadow: none !important; }

.was-validated .switcher-input:valid:checked ~ .switcher-indicator, .switcher-input.is-valid:checked ~ .switcher-indicator {
  background: #02BC77 !important;
  color: #fff !important; }

.was-validated .switcher-input:valid:focus ~ .switcher-indicator, .switcher-input.is-valid:focus ~ .switcher-indicator {
  box-shadow: 0 0 0 2px rgba(2, 188, 119, 0.4) !important; }

.was-validated .switcher-input:valid:active ~ .switcher-indicator, .switcher-input.is-valid:active ~ .switcher-indicator {
  box-shadow: none !important; }

.was-validated .switcher-input:valid ~ .switcher-label, .switcher-input.is-valid ~ .switcher-label {
  color: #02BC77 !important; }

.was-validated .switcher-input:invalid:checked ~ .switcher-indicator, .switcher-input.is-invalid:checked ~ .switcher-indicator {
  background: #d9534f !important;
  color: #fff !important; }

.was-validated .switcher-input:invalid:focus ~ .switcher-indicator, .switcher-input.is-invalid:focus ~ .switcher-indicator {
  box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.4) !important; }

.was-validated .switcher-input:invalid:active ~ .switcher-indicator, .switcher-input.is-invalid:active ~ .switcher-indicator {
  box-shadow: none !important; }

.was-validated .switcher-input:invalid ~ .switcher-label, .switcher-input.is-invalid ~ .switcher-label {
  color: #d9534f !important; }

.input-group .custom-select {
  flex: 0 1 auto;
  width: auto; }

.input-group-text {
  background-clip: padding-box; }

.modal {
  z-index: 1090; }

.modal-backdrop {
  z-index: 1089; }

.modal-content {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.09); }

.modal-header {
  position: relative;
  padding: 1.25rem 2.5625rem 1.25rem 1.5625rem; }

.modal-footer {
  padding: 1.25rem 1.5625rem; }

.modal-header .close,
.modal-slide .close {
  position: absolute;
  top: 50%;
  right: 1.5625rem;
  margin: 0;
  padding: 0;
  line-height: 1.47;
  transform: translate(0, -50%); }

@media (min-width: 576px) {
  .modal-content {
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.09); }
  ngb-modal-window.modal-sm {
    max-width: none; }
  .modal-sm .modal-dialog {
    max-width: 22.5rem; } }

@media (min-width: 992px) {
  ngb-modal-window.modal-lg {
    max-width: none; }
  .modal-lg .modal-dialog {
    max-width: 50rem; } }

.modal-top .modal-dialog {
  margin-top: 0; }

.modal-top .modal-content {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.modal-slide,
.modal-slide .modal {
  overflow: hidden !important;
  padding: 0 !important; }

.modal-slide .modal-dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  margin: 0;
  max-width: none;
  width: 100%; }
  @media (min-width: 576px) {
    .modal-slide .modal-dialog {
      width: 20rem; } }

.modal-slide .modal-content {
  overflow: auto;
  padding-top: 3.22127rem;
  padding-bottom: 3.22127rem;
  height: 100%;
  border-radius: 0; }

.modal-slide .modal-body {
  flex-grow: 0;
  margin: auto 0;
  padding-top: 0;
  padding-bottom: 0; }

.modal-slide .close {
  top: 0.625rem;
  z-index: 10;
  transform: none; }

.modal-fill-in .modal-dialog {
  display: flex;
  margin: 0 auto;
  padding-top: 3.22127rem;
  padding-bottom: 3.22127rem;
  min-height: 100vh; }

.modal-fill-in .modal-content {
  margin: auto;
  width: 100%;
  border: 0;
  background: transparent;
  box-shadow: none; }

.modal-fill-in .close {
  position: absolute;
  top: -2rem;
  right: 1.5625rem;
  font-size: 2rem;
  transform: none; }

.modal.fade .modal-dialog {
  transform: translateY(150px) scale(0.8); }

.modal.show .modal-dialog {
  transform: translateY(0) scale(1); }

.modal-top.fade .modal-dialog,
.modal-top .modal.fade .modal-dialog {
  transform: translateY(-100%); }

.modal-top.show .modal-dialog,
.modal-top .modal.show .modal-dialog {
  transform: translateY(0); }

.modal-slide.fade .modal-dialog,
.modal-slide .modal.fade .modal-dialog {
  transform: translateX(100%); }

.modal-slide.show .modal-dialog,
.modal-slide .modal.show .modal-dialog {
  transform: translateX(0) !important; }

.modal-fill-in.fade .modal-dialog,
.modal-fill-in .modal.fade .modal-dialog {
  transform: scale(0.5, 0.5); }

.modal-fill-in.show .modal-dialog,
.modal-fill-in .modal.show .modal-dialog {
  transform: scale(1, 1); }

.breadcrumb-item,
.breadcrumb-item a {
  color: #a3a4a6; }
  .breadcrumb-item:hover,
  .breadcrumb-item a:hover {
    color: #4E5155; }

.list-group-item-secondary {
  border-color: rgba(0, 0, 0, 0.07);
  background-color: #f3f5f7;
  color: #616263; }

a.list-group-item-secondary,
button.list-group-item-secondary {
  color: #616263; }
  a.list-group-item-secondary:hover, a.list-group-item-secondary:focus,
  button.list-group-item-secondary:hover,
  button.list-group-item-secondary:focus {
    background-color: #f1f3f5;
    color: #616263; }
  a.list-group-item-secondary.active,
  button.list-group-item-secondary.active {
    border-color: #8897AA;
    background-color: #8897AA;
    color: #616263; }

.list-group-item-success {
  border-color: rgba(0, 0, 0, 0.07);
  background-color: #e6f8f1;
  color: #5c6360; }

a.list-group-item-success,
button.list-group-item-success {
  color: #5c6360; }
  a.list-group-item-success:hover, a.list-group-item-success:focus,
  button.list-group-item-success:hover,
  button.list-group-item-success:focus {
    background-color: #e4f6ef;
    color: #5c6360; }
  a.list-group-item-success.active,
  button.list-group-item-success.active {
    border-color: #02BC77;
    background-color: #02BC77;
    color: #5c6360; }

.list-group-item-info {
  border-color: rgba(0, 0, 0, 0.07);
  background-color: #eaf9fb;
  color: #5e6464; }

a.list-group-item-info,
button.list-group-item-info {
  color: #5e6464; }
  a.list-group-item-info:hover, a.list-group-item-info:focus,
  button.list-group-item-info:hover,
  button.list-group-item-info:focus {
    background-color: #e8f7f8;
    color: #5e6464; }
  a.list-group-item-info.active,
  button.list-group-item-info.active {
    border-color: #28c3d7;
    background-color: #28c3d7;
    color: #5e6464; }

.list-group-item-warning {
  border-color: rgba(0, 0, 0, 0.07);
  background-color: #fffbee;
  color: #66645f; }

a.list-group-item-warning,
button.list-group-item-warning {
  color: #66645f; }
  a.list-group-item-warning:hover, a.list-group-item-warning:focus,
  button.list-group-item-warning:hover,
  button.list-group-item-warning:focus {
    background-color: #fcf8ec;
    color: #66645f; }
  a.list-group-item-warning.active,
  button.list-group-item-warning.active {
    border-color: #FFD950;
    background-color: #FFD950;
    color: #66645f; }

.list-group-item-danger {
  border-color: rgba(0, 0, 0, 0.07);
  background-color: #fbeeed;
  color: #645f5f; }

a.list-group-item-danger,
button.list-group-item-danger {
  color: #645f5f; }
  a.list-group-item-danger:hover, a.list-group-item-danger:focus,
  button.list-group-item-danger:hover,
  button.list-group-item-danger:focus {
    background-color: #f8eceb;
    color: #645f5f; }
  a.list-group-item-danger.active,
  button.list-group-item-danger.active {
    border-color: #d9534f;
    background-color: #d9534f;
    color: #645f5f; }

.list-group-item-dark {
  border-color: rgba(0, 0, 0, 0.07);
  background-color: #e8e8e9;
  color: #5d5d5d; }

a.list-group-item-dark,
button.list-group-item-dark {
  color: #5d5d5d; }
  a.list-group-item-dark:hover, a.list-group-item-dark:focus,
  button.list-group-item-dark:hover,
  button.list-group-item-dark:focus {
    background-color: #e6e6e7;
    color: #5d5d5d; }
  a.list-group-item-dark.active,
  button.list-group-item-dark.active {
    border-color: rgba(24, 28, 33, 0.9);
    background-color: rgba(24, 28, 33, 0.9);
    color: #5d5d5d; }

.navbar {
  z-index: 2; }

.fixed-top {
  z-index: 1030; }

.navbar.navbar-dark {
  color: rgba(255, 255, 255, 0.8); }

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.4) !important; }

.navbar.navbar-light {
  color: rgba(24, 28, 33, 0.4); }

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(24, 28, 33, 0.2) !important; }

.navbar-collapse,
.navbar-brand,
.navbar-text {
  flex-shrink: 1; }

.navbar-dark hr {
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-light hr {
  border-color: rgba(24, 28, 33, 0.06); }

.navbar-icon {
  font-size: 130%; }

.navbar-search-box {
  display: flex;
  align-items: center;
  margin: 0; }
  .navbar-search-box:not(.active) {
    cursor: pointer; }

.navbar-search-input {
  display: flex;
  overflow: hidden;
  align-items: center;
  max-width: 0;
  transition: max-width .3s ease-in-out; }
  .navbar-search-box.active .navbar-search-input {
    max-width: 100vw; }
  .navbar-search-input .form-control {
    padding-right: 0 !important;
    padding-left: 0 !important;
    width: auto;
    border: 0 !important;
    border-radius: 0 !important;
    background: none;
    color: inherit !important; }
    .navbar-dark .navbar-search-input .form-control::placeholder {
      color: rgba(255, 255, 255, 0.4); }
    .navbar-light .navbar-search-input .form-control::placeholder {
      color: rgba(24, 28, 33, 0.2); }

.navbar-search-cancel {
  color: inherit !important;
  font-weight: 100;
  font-size: 1.55em;
  line-height: 1;
  opacity: .5; }
  .navbar-search-cancel:hover, .navbar-search-cancel:focus {
    opacity: 1; }

.navbar-expand-sm .navbar-search-box.nav-link {
  padding-top: 0;
  padding-bottom: 0; }

@media (max-width: 575.98px) {
  .navbar-expand-sm .navbar-search-input {
    max-width: 100vw !important;
    width: 100% !important; }
  .navbar-expand-sm .navbar-search-input .form-control {
    width: 100% !important; }
  .navbar-expand-sm .navbar-search-cancel {
    display: none !important; }
  .navbar-expand-sm.navbar-dark .navbar-search-box {
    color: #fff !important; }
  .navbar-expand-sm.navbar-light .navbar-search-box {
    color: #4E5155 !important; } }

@media (min-width: 576px) {
  .navbar-expand-md .navbar-search-box.nav-link {
    padding-top: 0;
    padding-bottom: 0; } }

@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-search-input {
    max-width: 100vw !important;
    width: 100% !important; }
  .navbar-expand-md .navbar-search-input .form-control {
    width: 100% !important; }
  .navbar-expand-md .navbar-search-cancel {
    display: none !important; }
  .navbar-expand-md.navbar-dark .navbar-search-box {
    color: #fff !important; }
  .navbar-expand-md.navbar-light .navbar-search-box {
    color: #4E5155 !important; } }

@media (min-width: 768px) {
  .navbar-expand-lg .navbar-search-box.nav-link {
    padding-top: 0;
    padding-bottom: 0; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-search-input {
    max-width: 100vw !important;
    width: 100% !important; }
  .navbar-expand-lg .navbar-search-input .form-control {
    width: 100% !important; }
  .navbar-expand-lg .navbar-search-cancel {
    display: none !important; }
  .navbar-expand-lg.navbar-dark .navbar-search-box {
    color: #fff !important; }
  .navbar-expand-lg.navbar-light .navbar-search-box {
    color: #4E5155 !important; } }

@media (min-width: 992px) {
  .navbar-expand-xl .navbar-search-box.nav-link {
    padding-top: 0;
    padding-bottom: 0; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-search-input {
    max-width: 100vw !important;
    width: 100% !important; }
  .navbar-expand-xl .navbar-search-input .form-control {
    width: 100% !important; }
  .navbar-expand-xl .navbar-search-cancel {
    display: none !important; }
  .navbar-expand-xl.navbar-dark .navbar-search-box {
    color: #fff !important; }
  .navbar-expand-xl.navbar-light .navbar-search-box {
    color: #4E5155 !important; } }

@media (min-width: 1200px) {
  .navbar-expand .navbar-search-box.nav-link {
    padding-top: 0;
    padding-bottom: 0; } }

.navbar-expand .navbar-search-input {
  max-width: 100vw !important;
  width: 100% !important; }

.navbar-expand .navbar-search-input .form-control {
  width: 100% !important; }

.navbar-expand .navbar-search-cancel {
  display: none !important; }

.navbar-expand.navbar-dark .navbar-search-box {
  color: #fff !important; }

.navbar-expand.navbar-light .navbar-search-box {
  color: #4E5155 !important; }

.mega-dropdown .dropdown-menu {
  width: 100%; }

.mega-dropdown .dropdown-toggle {
  outline: 0; }

.card {
  background-clip: padding-box;
  box-shadow: 0 1px 4px rgba(24, 28, 33, 0.009); }

.card-link {
  display: inline-block; }

.card-header {
  line-height: 1.54; }

.card-header,
.card-footer,
.card hr {
  border-color: rgba(24, 28, 33, 0.06); }

.card .list-group-item {
  border-color: #f1f1f2; }

.card .row-bordered > [class^="col-"]::before, .card .row-bordered > [class^="col-"]::after,
.card .row-bordered > [class*=" col-"]::before,
.card .row-bordered > [class*=" col-"]::after,
.card .row-bordered > [class^="col "]::before,
.card .row-bordered > [class^="col "]::after,
.card .row-bordered > [class*=" col "]::before,
.card .row-bordered > [class*=" col "]::after,
.card .row-bordered > [class$=" col"]::before,
.card .row-bordered > [class$=" col"]::after,
.card .row-bordered > [class="col"]::before,
.card .row-bordered > [class="col"]::after {
  border-color: rgba(24, 28, 33, 0.06); }

.card-header.with-elements,
.card-title.with-elements {
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap; }

.card-header.with-elements {
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem; }

.card-header-elements,
.card-title-elements {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .card-header-elements + .card-header-elements,
  .card-header-elements > * + *,
  .card-title-elements + .card-header-elements,
  .card-title-elements > * + *, .card-header-elements +
  .card-title-elements,
  .card-title-elements +
  .card-title-elements {
    margin-left: .25rem; }

.card-header-title {
  padding: 0.4375rem 0; }

.card-header-elements {
  padding-top: .25rem;
  padding-bottom: .25rem; }

.card > .list-group .list-group-item {
  padding-right: 1.5rem;
  padding-left: 1.5rem; }

.card-condenced .card-header,
.card-condenced .card-footer,
.card-condenced > .list-group .list-group-item {
  padding-right: 1rem;
  padding-left: 1rem; }

.card-condenced .card-body,
.card-condenced .card-img-overlay {
  padding: 1rem; }

html:not([dir=rtl]) .card-hover[class],
html[dir=rtl] .card-hover[class] {
  transition: all .2s; }
  html:not([dir=rtl]) .card-hover[class]:not(:hover),
  html[dir=rtl] .card-hover[class]:not(:hover) {
    color: #4E5155 !important;
    border-color: transparent !important;
    background: transparent !important;
    box-shadow: none !important; }

.collapse-icon {
  position: relative;
  display: block;
  align-self: center;
  width: .875rem;
  height: .875rem; }
  .collapse-icon::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    margin-top: -.25rem;
    margin-left: -.25rem;
    width: .5rem;
    height: .5rem;
    border-bottom: 0.0625rem solid rgba(24, 28, 33, 0.3);
    border-left: 0.0625rem solid rgba(24, 28, 33, 0.3);
    transition: all .3s;
    transform: rotate(-45deg); }
    [aria-expanded="true"] .collapse-icon::after {
      transform: rotate(135deg); }

.accordion .card {
  border-radius: 0.25rem !important;
  border: 1px solid rgba(24, 28, 33, 0.06) !important; }
  .accordion .card .card-header:last-child {
    border-bottom: 0; }

.close:focus {
  outline: 0; }

.footer-link {
  display: inline-block; }

.footer-dark {
  color: rgba(255, 255, 255, 0.8); }
  .footer-dark .footer-link {
    color: rgba(255, 255, 255, 0.8); }
    .footer-dark .footer-link:hover, .footer-dark .footer-link:focus {
      color: #fff; }
    .footer-dark .footer-link.disabled {
      color: rgba(255, 255, 255, 0.4) !important; }
  .footer-dark .footer-text {
    color: #fff; }
  .footer-dark .show > .footer-link,
  .footer-dark .active > .footer-link,
  .footer-dark .footer-link.show,
  .footer-dark .footer-link.active {
    color: #fff; }
  .footer-dark hr {
    border-color: rgba(255, 255, 255, 0.2); }

.footer-light {
  color: rgba(24, 28, 33, 0.4); }
  .footer-light .footer-link {
    color: rgba(24, 28, 33, 0.4); }
    .footer-light .footer-link:hover, .footer-light .footer-link:focus {
      color: #4E5155; }
    .footer-light .footer-link.disabled {
      color: rgba(24, 28, 33, 0.2) !important; }
  .footer-light .footer-text {
    color: #4E5155; }
  .footer-light .show > .footer-link,
  .footer-light .active > .footer-link,
  .footer-light .footer-link.show,
  .footer-light .footer-link.active {
    color: #4E5155; }
  .footer-light hr {
    border-color: rgba(0, 0, 0, 0.06); }

.sidenav {
  display: flex; }
  .sidenav .ps__thumb-y,
  .sidenav .ps__rail-y {
    width: .125rem !important; }
  .sidenav .ps__rail-y {
    right: .25rem !important;
    left: auto !important;
    background: none !important; }
  .sidenav .ps__rail-y:hover,
  .sidenav .ps__rail-y:focus,
  .sidenav .ps__rail-y.ps--clicking,
  .sidenav .ps__rail-y:hover > .ps__thumb-y,
  .sidenav .ps__rail-y:focus > .ps__thumb-y,
  .sidenav .ps__rail-y.ps--clicking > .ps__thumb-y {
    width: .375rem !important; }

.sidenav-inner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  padding: 0; }

.sidenav-item,
.sidenav-header,
.sidenav-divider,
.sidenav-block {
  flex: 0 0 auto;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none; }

.sidenav-item {
  align-items: flex-start;
  justify-content: flex-start; }
  .sidenav-item.sidenav-item-animating {
    transition: height 0.2s ease-in-out; }

.sidenav-item .sidenav-link {
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 1 auto; }
  .sidenav-item.active > .sidenav-item .sidenav-link {
    font-weight: 500; }
  .sidenav-item.disabled .sidenav-item .sidenav-link {
    cursor: default !important; }
  .sidenav:not(.sidenav-no-animation) .sidenav-item .sidenav-link {
    transition-duration: 0.2s;
    transition-property: color, background-color; }
  .sidenav-item .sidenav-link > :not(.sidenav-icon) {
    flex: 0 1 auto; }

.sidenav-toggle::after {
  content: "";
  position: absolute;
  top: 50%;
  display: block;
  width: 0.42em;
  height: 0.42em;
  border: 1px solid;
  border-top: 0;
  border-right: 0;
  transform: translateY(-50%) rotate(45deg); }
  .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle::after {
    transform: translateY(-50%) rotate(-45deg); }
  .sidenav:not(.sidenav-no-animation) .sidenav-toggle::after {
    transition-duration: 0.2s;
    transition-property: -webkit-transform, transform; }

.sidenav-menu {
  display: none;
  flex-direction: column;
  margin: 0;
  padding: 0; }
  .sidenav:not(.sidenav-no-animation) .sidenav-menu {
    transition: background-color 0.2s; }
  .sidenav-item.open > .sidenav-menu {
    display: flex; }

.sidenav-icon {
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 0.25rem;
  font-size: 1rem; }

.sidenav-divider {
  width: 100%;
  border: 0;
  border-top: 1px solid; }

.sidenav-vertical {
  overflow: hidden;
  flex-direction: column; }
  .sidenav-vertical:not(.sidenav-no-animation) {
    transition: width 0.2s; }
  .sidenav-vertical,
  .sidenav-vertical .sidenav-block,
  .sidenav-vertical .sidenav-inner > .sidenav-item,
  .sidenav-vertical .sidenav-inner > .sidenav-header {
    width: 15.625rem; }
  .sidenav-vertical .sidenav-inner {
    flex-direction: column;
    flex: 1 1 auto; }
    .sidenav-vertical .sidenav-inner > .sidenav-item {
      margin: 0.0625rem 0; }
  .sidenav-vertical .sidenav-item .sidenav-link,
  .sidenav-vertical .sidenav-header,
  .sidenav-vertical .sidenav-block {
    padding: 0.75rem 2rem; }
  .sidenav-vertical .sidenav-divider {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    padding: 0; }
  .sidenav-vertical .sidenav-item .sidenav-toggle {
    padding-right: calc(2rem + 1.26em); }
    .sidenav-vertical .sidenav-item .sidenav-toggle::after {
      right: 2rem; }
  .sidenav-vertical .sidenav-menu {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem; }
    .sidenav-vertical .sidenav-menu .sidenav-link {
      padding-top: 0.625rem;
      padding-bottom: 0.625rem; }
  .sidenav-vertical .sidenav-icon {
    width: 1.75rem; }
  .sidenav-vertical .sidenav-menu .sidenav-icon {
    margin-right: 0; }
  .sidenav-vertical .sidenav-menu .sidenav-link {
    padding-left: 4rem; }
  .sidenav-vertical .sidenav-menu .sidenav-menu .sidenav-link {
    padding-left: 5rem; }
  .sidenav-vertical .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-link {
    padding-left: 6rem; }
  .sidenav-vertical .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-link {
    padding-left: 7rem; }
  .sidenav-vertical .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-link {
    padding-left: 8rem; }

.sidenav-horizontal {
  flex-direction: row;
  width: 100%; }
  .sidenav-horizontal .sidenav-inner {
    overflow: hidden;
    flex-direction: row;
    flex: 0 1 100%; }
  .sidenav-horizontal .sidenav-item .sidenav-link {
    padding: 1rem 1rem; }
  .sidenav-horizontal .sidenav-item .sidenav-toggle {
    padding-right: calc(1rem + 1.26em); }
    .sidenav-horizontal .sidenav-item .sidenav-toggle::after {
      right: 1rem; }
  .sidenav-horizontal .sidenav-inner > .sidenav-item > .sidenav-toggle::after {
    transform: translateY(-50%) rotate(-45deg); }
  .sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing).open > .sidenav-toggle::after {
    transform: translateY(-50%) rotate(135deg); }
  .sidenav-horizontal .sidenav-header,
  .sidenav-horizontal .sidenav-divider {
    display: none !important; }
  .sidenav-horizontal .sidenav-menu {
    position: absolute;
    width: 12.5rem; }
    .sidenav-horizontal .sidenav-menu .sidenav-menu {
      position: static;
      width: auto; }
    .sidenav-horizontal .sidenav-menu .sidenav-link {
      padding-top: 0.625rem;
      padding-bottom: 0.625rem; }
  .sidenav-horizontal .sidenav-inner > .sidenav-item > .sidenav-menu {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .sidenav-horizontal:not(.sidenav-no-animation) .sidenav-inner > .sidenav-item.open > .sidenav-menu {
    animation: sidenavDropdownShow 0.2s ease-in-out; }
  .sidenav-horizontal .sidenav-menu .sidenav-menu .sidenav-link {
    padding-left: 2rem; }
  .sidenav-horizontal .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-link {
    padding-left: 3rem; }
  .sidenav-horizontal .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-link {
    padding-left: 4rem; }
  .sidenav-horizontal .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-menu .sidenav-link {
    padding-left: 5rem; }

.sidenav-horizontal-wrapper {
  overflow: hidden;
  flex: 0 1 100%;
  width: 0; }
  .sidenav:not(.sidenav-no-animation) .sidenav-horizontal-wrapper .sidenav-inner {
    transition: margin 0.2s; }

.sidenav-horizontal-prev,
.sidenav-horizontal-next {
  position: relative;
  display: block;
  flex: 0 0 auto;
  width: 2.25rem; }
  .sidenav-horizontal-prev::after,
  .sidenav-horizontal-next::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    border: 1px solid;
    border-top: 0; }
  .sidenav-horizontal-prev.disabled,
  .sidenav-horizontal-next.disabled {
    cursor: default !important; }

.sidenav-horizontal-prev::after {
  border-right: 0;
  transform: translate(-50%, -50%) rotate(45deg); }

.sidenav-horizontal-next::after {
  border-left: 0;
  transform: translate(-50%, -50%) rotate(-45deg); }

@-webkit-keyframes sidenavDropdownShow {
  0% {
    opacity: 0;
    transform: translateY(-0.5rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@-moz-keyframes sidenavDropdownShow {
  0% {
    opacity: 0;
    transform: translateY(-0.5rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes sidenavDropdownShow {
  0% {
    opacity: 0;
    transform: translateY(-0.5rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

.sidenav-dark {
  color: rgba(255, 255, 255, 0.8); }
  .sidenav-dark .sidenav-link,
  .sidenav-dark .sidenav-horizontal-prev,
  .sidenav-dark .sidenav-horizontal-next {
    color: rgba(255, 255, 255, 0.8); }
    .sidenav-dark .sidenav-link:hover, .sidenav-dark .sidenav-link:focus,
    .sidenav-dark .sidenav-horizontal-prev:hover,
    .sidenav-dark .sidenav-horizontal-prev:focus,
    .sidenav-dark .sidenav-horizontal-next:hover,
    .sidenav-dark .sidenav-horizontal-next:focus {
      color: #fff; }
    .sidenav-dark .sidenav-link.active,
    .sidenav-dark .sidenav-horizontal-prev.active,
    .sidenav-dark .sidenav-horizontal-next.active {
      color: #fff; }
  .sidenav-dark .sidenav-item.disabled .sidenav-link {
    color: rgba(255, 255, 255, 0.4) !important; }
  .sidenav-dark .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
  .sidenav-dark .sidenav-item.active > .sidenav-link {
    color: #fff; }
  .sidenav-dark .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background: rgba(0, 0, 0, 0.06); }
  .sidenav-dark .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
  .sidenav-dark .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
    color: rgba(255, 255, 255, 0.8); }
  .sidenav-dark .sidenav-text {
    color: #fff; }
  .sidenav-dark .sidenav-header {
    color: rgba(255, 255, 255, 0.8); }
  .sidenav-dark hr,
  .sidenav-dark .sidenav-divider,
  .sidenav-dark .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
    border-color: rgba(255, 255, 255, 0.2) !important; }
  .sidenav-dark .sidenav-inner > .sidenav-header::before,
  .sidenav-dark .sidenav-block::before {
    background-color: rgba(255, 255, 255, 0.4); }
  .sidenav-dark .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
    background-color: rgba(255, 255, 255, 0.2); }
  .sidenav-dark .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    background-color: #fff; }
  .sidenav-dark .ps__thumb-y {
    background: rgba(255, 255, 255, 0.8) !important; }

.sidenav-light {
  color: rgba(24, 28, 33, 0.4); }
  .sidenav-light .sidenav-link,
  .sidenav-light .sidenav-horizontal-prev,
  .sidenav-light .sidenav-horizontal-next {
    color: rgba(24, 28, 33, 0.4); }
    .sidenav-light .sidenav-link:hover, .sidenav-light .sidenav-link:focus,
    .sidenav-light .sidenav-horizontal-prev:hover,
    .sidenav-light .sidenav-horizontal-prev:focus,
    .sidenav-light .sidenav-horizontal-next:hover,
    .sidenav-light .sidenav-horizontal-next:focus {
      color: #4E5155; }
    .sidenav-light .sidenav-link.active,
    .sidenav-light .sidenav-horizontal-prev.active,
    .sidenav-light .sidenav-horizontal-next.active {
      color: #4E5155; }
  .sidenav-light .sidenav-item.disabled .sidenav-link {
    color: rgba(24, 28, 33, 0.2) !important; }
  .sidenav-light .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
  .sidenav-light .sidenav-item.active > .sidenav-link {
    color: #4E5155; }
  .sidenav-light .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background: rgba(0, 0, 0, 0.05); }
  .sidenav-light .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
  .sidenav-light .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
    color: rgba(24, 28, 33, 0.4); }
  .sidenav-light .sidenav-text {
    color: #4E5155; }
  .sidenav-light .sidenav-header {
    color: rgba(24, 28, 33, 0.4); }
  .sidenav-light hr,
  .sidenav-light .sidenav-divider,
  .sidenav-light .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
    border-color: rgba(0, 0, 0, 0.06) !important; }
  .sidenav-light .sidenav-inner > .sidenav-header::before,
  .sidenav-light .sidenav-block::before {
    background-color: rgba(24, 28, 33, 0.2); }
  .sidenav-light .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
    background-color: rgba(0, 0, 0, 0.06); }
  .sidenav-light .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    background-color: #4E5155; }
  .sidenav-light .ps__thumb-y {
    background: rgba(24, 28, 33, 0.4) !important; }

.sidenav-collapsed:not(:hover) {
  width: 4.375rem; }
  .sidenav-collapsed:not(:hover) .sidenav-inner > .sidenav-item {
    width: 16.25rem; }
  .sidenav-collapsed:not(:hover) .sidenav-inner > .sidenav-item > .sidenav-link {
    padding-left: 4.375rem; }
  .sidenav-collapsed:not(:hover) .sidenav-inner > .sidenav-header,
  .sidenav-collapsed:not(:hover) .sidenav-block {
    position: relative;
    margin-left: 4.375rem;
    padding-right: 3.75rem;
    padding-left: 0.25rem;
    width: 15.625rem; }
    .sidenav-collapsed:not(:hover) .sidenav-inner > .sidenav-header::before,
    .sidenav-collapsed:not(:hover) .sidenav-block::before {
      content: "";
      position: absolute;
      top: 0.75rem;
      bottom: 0.75rem;
      left: -3.28125rem;
      display: block;
      width: 2.1875rem; }
  .sidenav-collapsed:not(:hover) .sidenav-inner > .sidenav-item > .sidenav-menu,
  .sidenav-collapsed:not(:hover) .sidenav-inner > .sidenav-item.open > .sidenav-menu {
    position: relative;
    margin-left: 0.625rem;
    background: none !important; }
    .sidenav-collapsed:not(:hover) .sidenav-inner > .sidenav-item > .sidenav-menu .sidenav-link,
    .sidenav-collapsed:not(:hover) .sidenav-inner > .sidenav-item.open > .sidenav-menu .sidenav-link {
      background: none !important;
      transition: none !important; }
  .sidenav-collapsed:not(:hover) .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(2.1875rem - 1px);
    display: block;
    margin-left: -0.625rem;
    width: 0;
    border-left: 2px solid; }
  .sidenav-collapsed:not(:hover) .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
  .sidenav-collapsed:not(:hover) .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    margin-top: -4px;
    margin-left: calc(1.5625rem - 4px);
    width: 8px;
    height: 8px;
    border-radius: 50%; }
  .sidenav-collapsed:not(:hover) .sidenav-inner > .sidenav-item > .sidenav-toggle::after {
    right: auto;
    left: 3.5rem; }
  .sidenav-collapsed:not(:hover) .sidenav-inner > .sidenav-item > .sidenav-link .sidenav-icon {
    margin-left: -4.375rem;
    width: 4.375rem;
    text-align: center; }

.layout-wrapper,
.layout-inner {
  display: flex;
  align-items: stretch;
  flex: 1 1 auto;
  width: 100%; }

.layout-wrapper {
  overflow: hidden; }

.layout-inner {
  min-height: 100vh; }

.layout-container,
.layout-content,
.layout-content > *,
.layout-sidenav {
  min-height: 1px; }

.layout-container {
  display: flex;
  align-items: stretch;
  flex: 1 1 auto;
  padding: 0; }
  .layout-without-sidenav .layout-container {
    padding-right: 0 !important;
    padding-left: 0 !important; }

.layout-content {
  display: flex;
  align-items: stretch;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between; }

.layout-navbar,
.layout-footer {
  flex: 0 0 auto; }

.layout-navbar {
  position: relative;
  z-index: 2; }
  .layout-navbar .navbar {
    transform: translate3d(0, 0, 0); }

.layout-sidenav {
  position: relative;
  flex: 1 0 auto; }
  .layout-sidenav .sidenav {
    transform: translate3d(0, 0, 0); }
  .layout-sidenav .sidenav-vertical {
    height: 100%; }

.layout-1 .layout-inner {
  flex-direction: column; }

.layout-1 .layout-content {
  flex-basis: 100%;
  width: 0;
  min-width: 0;
  max-width: 100%; }

.layout-2 .layout-container {
  flex-basis: 100%;
  flex-direction: column;
  width: 0;
  min-width: 0;
  max-width: 100%; }

.layout-2 .layout-content {
  width: 100%; }

.layout-reversed .layout-1 .layout-container {
  flex-direction: row-reverse; }

.layout-reversed .layout-2 .layout-inner {
  flex-direction: row-reverse; }

.layout-sidenav-toggle {
  display: block; }

@media (max-width: 991px) {
  .layout-sidenav {
    position: fixed !important;
    top: 0 !important;
    height: 100% !important;
    left: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    transform: translate3d(-100%, 0, 0);
    will-change: transform, -webkit-transform; }
    .layout-reversed .layout-sidenav {
      right: 0 !important;
      left: auto !important;
      transform: translate3d(100%, 0, 0); }
    .layout-expanded .layout-sidenav {
      transform: translate3d(0, 0, 0) !important; }
  .layout-expanded body {
    overflow: hidden; }
  .layout-overlay {
    position: fixed;
    top: 0;
    right: 0;
    height: 100% !important;
    left: 0;
    display: none;
    background: #181C21;
    opacity: 0.5;
    cursor: pointer; }
    .layout-expanded .layout-overlay {
      display: block; }
  .layout-sidenav-100vh .layout-sidenav,
  .layout-sidenav-100vh .layout-overlay {
    height: 100vh !important; } }

@media (min-width: 992px) {
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical,
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) {
    width: 4.375rem; }
    .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item,
    .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item {
      width: 16.25rem; }
    .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item > .sidenav-link,
    .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item > .sidenav-link {
      padding-left: 4.375rem; }
    .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-header,
    .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-block,
    .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-header,
    .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-block {
      position: relative;
      margin-left: 4.375rem;
      padding-right: 3.75rem;
      padding-left: 0.25rem;
      width: 15.625rem; }
      .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-header::before,
      .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-block::before,
      .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-header::before,
      .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-block::before {
        content: "";
        position: absolute;
        top: 0.75rem;
        bottom: 0.75rem;
        left: -3.28125rem;
        display: block;
        width: 2.1875rem; }
    .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item > .sidenav-menu,
    .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item.open > .sidenav-menu,
    .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item > .sidenav-menu,
    .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item.open > .sidenav-menu {
      position: relative;
      margin-left: 0.625rem;
      background: none !important; }
      .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item > .sidenav-menu .sidenav-link,
      .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item.open > .sidenav-menu .sidenav-link,
      .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item > .sidenav-menu .sidenav-link,
      .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item.open > .sidenav-menu .sidenav-link {
        background: none !important;
        transition: none !important; }
    .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item.open > .sidenav-menu::before,
    .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(2.1875rem - 1px);
      display: block;
      margin-left: -0.625rem;
      width: 0;
      border-left: 2px solid; }
    .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
    .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before,
    .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
    .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      margin-top: -4px;
      margin-left: calc(1.5625rem - 4px);
      width: 8px;
      height: 8px;
      border-radius: 50%; }
    .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item > .sidenav-toggle::after,
    .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item > .sidenav-toggle::after {
      right: auto;
      left: 3.5rem; }
    .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .sidenav-vertical .sidenav-inner > .sidenav-item > .sidenav-link .sidenav-icon,
    .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical:not(:hover) .sidenav-inner > .sidenav-item > .sidenav-link .sidenav-icon {
      margin-left: -4.375rem;
      width: 4.375rem;
      text-align: center; }
  .layout-collapsed .layout-sidenav:hover {
    margin-right: -11.25rem; }
  .layout-collapsed.layout-reversed .layout-sidenav:hover {
    margin-right: 0;
    margin-left: -11.25rem; } }

@media (min-width: 992px) {
  .layout-collapsed.layout-offcanvas .layout-sidenav {
    margin-right: -15.625rem;
    transform: translateX(-100%); }
  .layout-collapsed.layout-offcanvas.layout-reversed .layout-sidenav {
    margin-right: 0;
    margin-left: -15.625rem;
    transform: translateX(100%); } }

@media (min-width: 992px) {
  .layout-fixed .layout-sidenav,
  .layout-fixed-offcanvas .layout-sidenav {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .layout-fixed.layout-reversed .layout-sidenav,
  .layout-fixed-offcanvas.layout-reversed .layout-sidenav {
    right: 0;
    left: auto; }
  .layout-fixed-offcanvas.layout-collapsed .layout-sidenav {
    transform: translateX(-100%); }
  .layout-fixed-offcanvas.layout-collapsed.layout-reversed .layout-sidenav {
    transform: translateX(100%); }
  .layout-fixed:not(.layout-collapsed) .layout-container,
  .layout-fixed-offcanvas:not(.layout-collapsed) .layout-container {
    padding-left: 15.625rem; }
  .layout-fixed:not(.layout-collapsed).layout-reversed .layout-container,
  .layout-fixed-offcanvas:not(.layout-collapsed).layout-reversed .layout-container {
    padding-right: 15.625rem;
    padding-left: 0; }
  .layout-fixed.layout-collapsed .layout-container {
    padding-left: 4.375rem; }
  .layout-fixed.layout-collapsed.layout-reversed .layout-container {
    padding-right: 4.375rem;
    padding-left: 0; } }

html:not(.layout-navbar-fixed):not(.layout-fixed):not(.layout-fixed-offcanvas) .layout-container,
html:not(.layout-navbar-fixed) .layout-2 .layout-container {
  padding-top: 0 !important; }

html:not(.layout-footer-fixed) .layout-content {
  padding-bottom: 0 !important; }

@media (max-width: 991px) {
  .layout-fixed .layout-wrapper.layout-1 .layout-sidenav,
  .layout-fixed-offcanvas .layout-wrapper.layout-1 .layout-sidenav {
    top: 0 !important; }
  html:not(.layout-navbar-fixed) .layout-1 .layout-container {
    padding-top: 0 !important; } }

.layout-navbar-fixed .layout-navbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0; }

@media (min-width: 992px) {
  .layout-fixed .layout-1 .layout-navbar,
  .layout-fixed-offcanvas .layout-1 .layout-navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0; }
  .layout-navbar-fixed:not(.layout-collapsed) .layout-2 .layout-navbar,
  .layout-fixed.layout-navbar-fixed:not(.layout-collapsed) .layout-2 .layout-navbar,
  .layout-fixed-offcanvas.layout-navbar-fixed:not(.layout-collapsed) .layout-2 .layout-navbar {
    left: 15.625rem; }
  .layout-navbar-fixed:not(.layout-collapsed).layout-reversed .layout-2 .layout-navbar,
  .layout-fixed.layout-navbar-fixed:not(.layout-collapsed).layout-reversed .layout-2 .layout-navbar,
  .layout-fixed-offcanvas.layout-navbar-fixed:not(.layout-collapsed).layout-reversed .layout-2 .layout-navbar {
    right: 15.625rem;
    left: 0; }
  .layout-navbar-fixed.layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-2 .layout-navbar,
  .layout-fixed.layout-navbar-fixed.layout-collapsed .layout-2 .layout-navbar {
    left: 4.375rem; }
  .layout-navbar-fixed.layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas).layout-reversed .layout-2 .layout-navbar,
  .layout-fixed.layout-navbar-fixed.layout-collapsed.layout-reversed .layout-2 .layout-navbar {
    right: 4.375rem;
    left: 0; } }

.layout-footer-fixed .layout-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0; }

@media (min-width: 992px) {
  .layout-footer-fixed:not(.layout-collapsed) .layout-wrapper:not(.layout-without-sidenav) .layout-footer {
    left: 15.625rem; }
  .layout-footer-fixed:not(.layout-collapsed).layout-reversed .layout-wrapper:not(.layout-without-sidenav) .layout-footer {
    right: 15.625rem;
    left: 0; }
  .layout-footer-fixed.layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-wrapper:not(.layout-without-sidenav) .layout-footer {
    left: 4.375rem; }
  .layout-footer-fixed.layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas).layout-reversed .layout-wrapper:not(.layout-without-sidenav) .layout-footer {
    right: 4.375rem;
    left: 0; } }

.layout-navbar-fixed body:not(.modal-open) .layout-1 .layout-navbar,
.layout-fixed body:not(.modal-open) .layout-1 .layout-navbar,
.layout-fixed-offcanvas body:not(.modal-open) .layout-1 .layout-navbar {
  z-index: 1080; }

.layout-navbar-fixed body:not(.modal-open) .layout-2 .layout-navbar,
.layout-fixed body:not(.modal-open) .layout-2 .layout-navbar,
.layout-fixed-offcanvas body:not(.modal-open) .layout-2 .layout-navbar {
  z-index: 1075; }

.layout-footer-fixed .layout-footer {
  z-index: 1030; }

.layout-sidenav-horizontal {
  z-index: 9; }

@media (max-width: 991px) {
  .layout-sidenav {
    z-index: 1100; }
  .layout-overlay {
    z-index: 1099; } }

@media (min-width: 992px) {
  .layout-1 .layout-navbar {
    z-index: 10; }
  .layout-1 .layout-sidenav {
    z-index: 9; }
  .layout-2 .layout-navbar {
    z-index: 9; }
  .layout-2 .layout-sidenav {
    z-index: 10; }
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-1 .layout-sidenav:hover {
    z-index: 1075 !important; }
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-2 .layout-sidenav {
    z-index: 1085 !important; }
  .layout-fixed body:not(.modal-open) .layout-1 .layout-sidenav,
  .layout-fixed-offcanvas body:not(.modal-open) .layout-1 .layout-sidenav {
    z-index: 1075; }
  .layout-navbar-fixed body:not(.modal-open) .layout-2 .layout-sidenav,
  .layout-fixed body:not(.modal-open) .layout-2 .layout-sidenav,
  .layout-fixed-offcanvas body:not(.modal-open) .layout-2 .layout-sidenav {
    z-index: 1080; } }

.layout-sidenav-link-no-transition .layout-sidenav .sidenav-link,
.layout-sidenav-link-no-transition .layout-sidenav-horizontal .sidenav-link {
  transition: none !important;
  animation: none !important; }

.layout-no-transition .layout-sidenav,
.layout-no-transition .layout-sidenav .sidenav,
.layout-no-transition .layout-sidenav .sidenav-item,
.layout-no-transition .layout-sidenav-horizontal,
.layout-no-transition .layout-sidenav-horizontal .sidenav,
.layout-no-transition .layout-sidenav-horizontal .sidenav-item {
  transition: none !important;
  animation: none !important; }

@media (max-width: 991px) {
  .layout-transitioning .layout-overlay {
    animation: layoutSidenavAnimation 0.2s; }
  .layout-transitioning .layout-sidenav {
    transition-duration: 0.2s;
    transition-property: transform, -webkit-transform; } }

@media (min-width: 992px) {
  .layout-collapsed:not(.layout-transitioning):not(.layout-offcanvas):not(.layout-fixed):not(.layout-fixed-offcanvas) .layout-sidenav {
    transition-duration: 0.2s;
    transition-property: margin-left, margin-right, width; }
  .layout-transitioning.layout-offcanvas .layout-sidenav {
    transition-duration: 0.2s;
    transition-property: margin-left, margin-right, transform, -webkit-transform; }
  .layout-transitioning.layout-fixed .layout-container, .layout-transitioning.layout-fixed-offcanvas .layout-container {
    transition-duration: 0.2s;
    transition-property: padding-left, padding-right; }
  .layout-transitioning.layout-fixed .layout-sidenav {
    transition: width 0.2s; }
  .layout-transitioning.layout-fixed-offcanvas .layout-sidenav {
    transition-duration: 0.2s;
    transition-property: transform, -webkit-transform; }
  .layout-transitioning.layout-navbar-fixed .layout-2 .layout-navbar,
  .layout-transitioning.layout-footer-fixed .layout-footer {
    transition-duration: 0.2s;
    transition-property: left, right; }
  .layout-transitioning:not(.layout-offcanvas):not(.layout-fixed):not(.layout-fixed-offcanvas) .layout-sidenav {
    transition-duration: 0.2s;
    transition-property: margin-left, margin-right, width; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sidenav,
  .layout-sidenav,
  .layout-container,
  .layout-navbar,
  .layout-footer {
    transition: none !important;
    transition-duration: 0s !important; }
  .layout-overlay {
    animation: none !important; } }

@-webkit-keyframes layoutSidenavAnimation {
  0% {
    opacity: 0; }
  100% {
    opacity: 0.5; } }

@-moz-keyframes layoutSidenavAnimation {
  0% {
    opacity: 0; }
  100% {
    opacity: 0.5; } }

@keyframes layoutSidenavAnimation {
  0% {
    opacity: 0; }
  100% {
    opacity: 0.5; } }

.app-brand {
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  line-height: 1;
  min-height: 1px; }

.app-brand-logo {
  display: block;
  overflow: hidden;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 1px; }
  .app-brand-logo img,
  .app-brand-logo svg {
    display: block; }

.app-brand-text {
  display: block;
  flex-shrink: 0; }

.app-brand .layout-sidenav-toggle {
  display: block; }

.sidenav-vertical .app-brand {
  padding-right: 1.75rem;
  padding-left: 1.75rem; }

.sidenav-horizontal .app-brand,
.sidenav-horizontal .app-brand + .sidenav-divider {
  display: none !important; }

:not(.layout-sidenav) > .sidenav-vertical.sidenav-collapsed:not(.layout-sidenav):not(:hover) .app-brand {
  padding-right: 0;
  padding-left: 0;
  width: 4.375rem; }

:not(.layout-sidenav) > .sidenav-vertical.sidenav-collapsed:not(.layout-sidenav):not(:hover) .app-brand-logo,
:not(.layout-sidenav) > .sidenav-vertical.sidenav-collapsed:not(.layout-sidenav):not(:hover) .app-brand-text {
  margin-right: auto;
  margin-left: auto; }

:not(.layout-sidenav) > .sidenav-vertical.sidenav-collapsed:not(.layout-sidenav):not(:hover) .app-brand-logo ~ .app-brand-text,
:not(.layout-sidenav) > .sidenav-vertical.sidenav-collapsed:not(.layout-sidenav):not(:hover) .app-brand .layout-sidenav-toggle {
  display: none; }

@media (min-width: 992px) {
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .app-brand {
    padding-right: 0;
    padding-left: 0;
    width: 4.375rem; }
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .app-brand-logo,
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .app-brand-text {
    margin-right: auto;
    margin-left: auto; }
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .app-brand-logo ~ .app-brand-text,
  .layout-collapsed:not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav:not(:hover) .app-brand .layout-sidenav-toggle {
    display: none; } }
